import React from 'react'
import styled from 'styled-components'
import { useTransition, animated } from 'react-spring'
import parser from 'react-html-parser'

const GlobalBanner = ({ announcementText, bannerColor }) => {
	const transitions = useTransition(true, null, {
		from: { transform: 'translateY(-15px)', opacity: 0 },
		enter: {
			opacity: 1,
			transform: 'translateY(0)',
		},
	})

	return transitions.map(
		({ item, key, props }) =>
			item && (
				<Banner key={key} style={props} background={bannerColor}>
					{parser(announcementText)}
				</Banner>
			)
	)
}

const Banner = styled(animated.div)`
	background: ${props => props.background};
	padding: 5px 15px;
	text-align: center;

	@media (max-width: 767px) {
		font-size: 85%;
	}
`

export default GlobalBanner
