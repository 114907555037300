import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MapMarker = ({ title, lat, lng, $hover }) => (
  <Marker
    hovered={$hover}
    href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&origin=`}
    target="_blank"
  >
    <span>{title}</span>
    <FontAwesomeIcon icon="map-pin" size="3x" />
  </Marker>
);

const EventMap = ({ coords, title }) => (
  <MapContainer>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: 'AIzaSyDgqA6yprSz4VIjXqP8MXU_etQ57KCRfXQ',
      }}
      defaultCenter={coords}
      defaultZoom={11}
      hoverDistance={40}
    >
      <MapMarker lat={coords.lat} lng={coords.lng} title={title} />
    </GoogleMapReact>
  </MapContainer>
);

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  resize: both;
  overflow: auto;
  padding-right: 10px;
  padding-bottom: 10px;
`;

const Marker = styled.a`
  text-align: center;

  span {
    opacity: ${props => (props.hovered ? 1 : 0)};
    position: absolute;
    font-weight: bold;
    top: -40px;
    color: white;
    background-color: ${props => props.theme.darkBlue};
    padding: 5px;
  }

  svg {
    color: ${props =>
      props.hovered ? props.theme.darkBlue : props.theme.mediumRed};
  }
`;

MapMarker.propTypes = {
  title: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  $hover: PropTypes.bool,
};

EventMap.propTypes = {
  title: PropTypes.string,
  coords: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default EventMap;
