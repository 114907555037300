/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Droppable } from 'react-beautiful-dnd'
import parse from 'react-html-parser'

import { useAuth } from '../../../utilities/useAuth'
import { useResources } from '../../../utilities/useResources'
import Group from './Group'
import AddGroup from './AddGroup'
import Fab from '../../../styles/Fab'
import SquaredButton from '../../../styles/SquaredButton'
import Search from '../../search/Search'

const Panel = ({ currentPanel, reorderedPanel, searchTerm, setSearchTerm }) => {

	const [addingGroup, setAddingGroup] = useState(false)

	const auth = useAuth()
	const resourceContext = useResources()
	const {
		isEditing,
		setIsEditing,
		isReordering,
		setIsReordering,
		toggleReordering,
		isReorderingPanels,
		groupAdded,
		addCollection,
		reorderPanelContents,
		savingReorder,
		initialSidebarPanels,
	} = resourceContext

	const { resourcePanelId: panelId, id, title: panelTitle, groups } = currentPanel


	// don't carry over editing or reordering state when currentPanel changes
	const firstUpdate = useRef(panelId)


	useEffect(() => {
		if (firstUpdate.current !== panelId) {
			setIsEditing(false)
			setIsReordering(false)
			firstUpdate.current = panelId
			return
		}
	}, [panelId])

	// Close input when group is added
	useEffect(() => {
		if (addingGroup && groupAdded) {
			setAddingGroup(false)
		}
	}, [groupAdded])

	const saveNewGroup = title => {
		addCollection({ panelId, title })
	}

	const saveReorderedPanel = () => {

		reorderPanelContents(panelId, reorderedPanel.groups)
	}
	return (
		<StyledPanel reorderingPanels={isReorderingPanels}>
			<div className='panel-title-row'>
				{searchTerm.length > 2 ? <h2>Search Results</h2> : 
				<>			
					<h2>{parse(panelTitle)}</h2> 
					<div className='key'>
						<p>
							<FontAwesomeIcon icon='asterisk' className='new-badge' /> = New Resource
						</p>
						<p>
							<FontAwesomeIcon icon='flag' className='updated-badge' /> = Updated Resource
						</p> 
					</div>
				</> }
						<div className= {searchTerm.length < 2 ? 'edit-reorder-btns' : 'edit-reorder-btns2'}> 
							{searchTerm.length < 2 && auth.user.roleIndex <= 2 ? 	
							<>			
								{!isReordering && (
									<SquaredButton className="edit" onClick={() => {
										setIsEditing(!isEditing)
										if(isEditing){ 
											setIsReordering(true);
											reorderPanelContents(panelId, currentPanel.groups);
											}
											}}
										
										save={isEditing}>
										<span>{!isEditing ? 'Edit' : 'Done'}</span>
										<FontAwesomeIcon icon={['far', 'edit']} />
									</SquaredButton>
								)}
								{!isEditing && (
									<SquaredButton className="reorder" onClick={toggleReordering} cancel={isReordering} orange={!isReordering}>
										<span>{!isReordering ? 'Reorder' : 'Cancel'}</span>
										{isReordering ? <FontAwesomeIcon icon={['far', 'window-close']} /> : <FontAwesomeIcon icon='random' />}
									</SquaredButton>
								)}
								{!isEditing && isReordering && (
									<SquaredButton onClick={() => reorderPanelContents(panelId, reorderedPanel.groups)} save>
										<span>Save</span>
										{savingReorder ? <FontAwesomeIcon icon='spinner' spin /> : <FontAwesomeIcon icon='random' />}
									</SquaredButton>
								)}
								
							</> : <></>}
							<input type="text" value={searchTerm} id="search" placeholder="Search.." className={ searchTerm.length < 3 ? "search-bar" : "search-bar2"} onChange={(e) => setSearchTerm(e.target.value)}/>
						</div>
				
			</div>

			
			{!searchTerm.trim() == "" && searchTerm.length > 2 ? 
				<Droppable droppableId={id} type='groups'>
					{provided => (
						<div className='groups-section' ref={provided.innerRef} {...provided.droppableProps}>
							<Search searchTerm = {searchTerm} currentPanel={initialSidebarPanels} />
							{provided.placeholder}
						</div>
					)}
				</Droppable> 
				: 
				<Droppable droppableId={id} type='groups'>
					{provided => (
						<div className='groups-section' ref={provided.innerRef} {...provided.droppableProps}>
							{isReordering && reorderedPanel.groups
								? reorderedPanel.groups.map((group, index) => (
										<Group panelId={panelId} groupIndex={index} group={group} key={group.id} />
									))
								: groups.map((group, index) => (
										<Group panelId={panelId} groupIndex={index} group={group} key={group.id} />
									))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			}

			{isEditing &&
				(addingGroup ? (
					<AddGroup level='Group' handleShow={setAddingGroup} handleSubmit={saveNewGroup} />
				) : (
					<Fab>
						<button className='add-collection-btn' onClick={() => setAddingGroup(true)}>
							<FontAwesomeIcon icon='plus' />
							<p>Add Group</p>
						</button>
					</Fab>
				))}
		</StyledPanel>
	)
}

const StyledPanel = styled.div`
	pointer-events: ${({ reorderingPanels }) => (reorderingPanels ? 'none' : 'auto')};
	opacity: ${({ reorderingPanels }) => (reorderingPanels ? 0.5 : 1)};

	.panel-title-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 25px 0;
		position: sticky;
		top: 100px;
		background: #f5f8fd;
		z-index: 10;
		border-bottom: 1px solid rgba(89, 89, 89, 0.2);
		margin-bottom: 40px;

		h2 {
			margin: 0 15px 15px 0;

		}

		.edit-reorder-btns {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			gap: 0px 0px;
			grid-template-areas:
				"Button-1 Button-2"
				"Search Search";
		}
		.edit-reorder-btns2 {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			gap: 0px 0px;
			grid-template-areas:
				"Button-1 Button-2"
				"Search Search";
		}

		.edit{ 
			grid-area: Button-1; 
		}
		.reorder{ 
			grid-area: Button-2; 
		}
		.search-bar{ 
			grid-area: Search;
			margin-left:10px;
			margin-top:10px;
		}

		.new-badge {
			color: #da2c38;
		}

		.updated-badge {
			color: #3581b8;
		}

		@media (max-width: 767px) {
			flex-direction: column;

			h2 {
				padding: 0 25px;
			}
			.edit-reorder-btns2{
				padding-left:75px !important;
			}

			.key {
				display: flex;

				p:first-child {
					margin-right: 10px;
				}
			}
		}
	}

	.search-bar{
		padding: 5px 5px 5px 5px;
	}
	.search-bar2{
		width: 150%;
		height: 120%;
	}


	.groups-section {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 25px auto;
	}
`

export default Panel

