import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { GET_DISTRICTS } from '../graphql/queries';
import StateSelect from './StateSelect';
import Select from './Select';
import createMarkup from '../utilities/createMarkup';

const DistrictPicker = ({ updateDistricts, districts, reload }) => {
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');

  useEffect(() => {
    setSelectedState('');
    setSelectedDistrict('');
  }, [reload]);

  const [
    loadDistricts,
    { loading: districtsLoading, error: districtsError, data: districtsData },
  ] = useLazyQuery(GET_DISTRICTS);

  const handleSelectState = stateId => {
    setSelectedState(stateId);
    loadDistricts({
      variables: {
        id: stateId,
      },
    });
  };

  const handleAddDistrict = index => {
    setSelectedDistrict(index);
    updateDistricts([
      ...districts,
      districtsData.stateBy.customFields.stateToDistricts[index],
    ]);
  };

  const handleRemoveDistrict = index => {
    const newDistricts = districts.slice();
    newDistricts.splice(index, 1);
    updateDistricts(newDistricts);
  };

  return (
    <div className="fieldset">
      <h3 className="full">Advocate's District(s)</h3>

      <StateSelect
        handleSelect={handleSelectState}
        selectedValue={selectedState}
        className="half"
      />
      {(districtsData || districtsLoading) && (
        <Select
          title="District"
          handleSelect={handleAddDistrict}
          selectedValue={selectedDistrict}
          className="half"
          loading={districtsLoading}
        >
          {districtsError && <option>Error! No results returned</option>}

          {districtsData &&
            districtsData.stateBy &&
            districtsData.stateBy.customFields.stateToDistricts.map(
              (dist, index) => (
                <option
                  value={index}
                  key={dist.districtId}
                  dangerouslySetInnerHTML={createMarkup(dist.title)}
                />
              )
            )}
        </Select>
      )}
      {districts && districts.length > 0 && (
        <div className="selected-districts">
          <h4>Selected Districts</h4>
          <ul>
            {districts.map((dist, index) => (
              <li key={dist.districtId}>
                <FontAwesomeIcon
                  icon={["far", "trash-alt"]}
                  size="1x"
                  onClick={() => handleRemoveDistrict(index)}
                  className="remove-btn"
                />
                <p dangerouslySetInnerHTML={createMarkup(dist.title)} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

DistrictPicker.propTypes = {
  updateDistricts: PropTypes.func,
  districts: PropTypes.array,
  reload: PropTypes.bool,
};

export default DistrictPicker;
