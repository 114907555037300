import styled from 'styled-components'

const ListItem = styled.li`
	list-style: none;
	margin: 10px auto;
	background-color: ${props => props.theme.liBackground};
	box-shadow: ${props => props.theme.liBoxShadow};
	transition: box-shadow 0.2s ${props => props.theme.animation};

	&:hover {
		box-shadow: ${props => props.theme.liBoxShadowHovered};
		position: relative;

		.chevron {
			transform: translateX(3px);
		}
	}

	a {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 30px;
		color: ${props => props.theme.darkText};
		text-decoration: none;

		h3 {
			margin: 0 auto;
		}
	}

	small {
		color: ${props => props.theme.lightText};
	}

	.chevron {
		font-size: 18px;
		transition: all 0.25s ${props => props.theme.animation};
	}
`

export default ListItem
