import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Sidebar = ({ active, setActive }) => (
  <Aside>
    <ul>
      <Item active={active === 'create'}>
        <button type="button" onClick={() => setActive('create')}>
          <FontAwesomeIcon icon="user-plus" />
          Create User
          <FontAwesomeIcon icon="chevron-right" className="chevron" />
        </button>
      </Item>
      <Item active={active === 'edit'}>
        <button type="button" onClick={() => setActive('edit')}>
          <FontAwesomeIcon icon="user-edit" />
          Edit User
          <FontAwesomeIcon icon="chevron-right" className="chevron" />
        </button>
      </Item>
    </ul>
  </Aside>
);

const Aside = styled.aside`
  min-height: calc(100vh - 65px);
  width: 250px;
  background-color: ${props => props.theme.lightBlue};
  padding: 30px 0;
  resize: both;
  overflow: hidden;
  box-shadow: ${props => props.theme.defaultBoxShadow};
  z-index: 10;

  ul {
    list-style: none;
    padding: 0;
  }
`;

const Item = styled.li`
  margin: 15px 0;
  background: ${props => (props.active ? '#fff' : 'transparent')};
  border-left: ${props =>
    props.active ? `4px solid ${props.theme.mediumBlue}` : 'none'};
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-right: 10px;

  button {
    padding: 10px 10px 10px 25px;
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    background: none;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 1em;
    color: ${props =>
      props.active ? props.theme.mediumBlue : props.theme.darkText};

    svg {
      margin-right: 10px;

      &.chevron {
        position: absolute;
        right: 0;
        display: ${props => (props.active ? 'inline' : 'none')};
      }
    }
  }
`;

export default Sidebar;
