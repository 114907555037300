/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Button from '../../global/Button';
import Form from '../../styles/UserForm.js';
import { GET_USER_BY_ID } from '../../graphql/queries';
import {
  CREATE_USER,
  UPDATE_USER,
  UPDATE_USER_ACF,
  CHANGE_PASSWORD,
} from '../../graphql/mutations';
import { generateUniqueId } from '../../utilities/generateUniqueId';
import Error from '../../global/Error';
import Success from '../../global/Success';
import Select from '../../global/Select';

import SelectUserSidebar from './SelectUserSidebar';
import DistrictPicker from '../../global/DistrictPicker.js';

const UserForm = ({ formFunction, loading: usersLoading, users }) => {
  const { id } = useParams();
  const client = useApolloClient();

  const initialState = {
    selectedUserId: '',
    username: '',
    password: '',
    willChangePassword: false,
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    userLoading: false,
    districts: [],
    successMessage: '',
    errorMessage: '',
  };

  const [state, setState] = useState(initialState);
  const [reload, setReload] = useState(false);

  const {
    selectedUserId,
    firstName,
    lastName,
    username,
    userLoading,
    email,
    password,
    willChangePassword,
    role,
    districts,
    successMessage,
    errorMessage,
  } = state;

  useEffect(() => {
    setState({
      ...state,
      successMessage: '',
      errorMessage: '',
    });
  }, [
    firstName,
    lastName,
    username,
    email,
    password,
    role,
    districts,
    selectedUserId,
  ]);

  useEffect(() => {
    setState(initialState);
    setReload(!reload);
    if (formFunction === 'create') {
      setState({
        ...initialState,
        password: generateUniqueId(),
        willChangePassword: true,
      });
    }
  }, [formFunction]);

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: res => {
      setState({ ...state, successMessage: 'Password successfully changed!' });
    },
    onError: err => {
      setState({ ...state, errorMessage: `Error! ${err.message}` });
      console.log('error: ', err.message);
    },
  });

  const [updateUserAcf, { loading: acfLoading }] = useMutation(
    UPDATE_USER_ACF,
    {
      onCompleted: res => {
        setState({
          ...state,
          successMessage: 'Success! The user has been updated.',
        });
      },
      onError: err => {
        setState({ ...state, errorMessage: `Error! ${err.message}` });
        console.log('error: ', err.message);
      },
    }
  );

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onCompleted: result => {
      if (result && result.createUser) {
        setState({
          ...state,
          successMessage: `Success! ${result.createUser.user.username} has been created.`,
        });
        const districtIds = districts.map(d => d.districtId);

        updateUserAcf({
          variables: {
            userId: result.createUser.user.userId,
            clientMutationId: generateUniqueId(),
            districts: districtIds,
          },
        });
      }
    },
    onError: err => {
      setState({ ...state, errorMessage: `Error! ${err.message}.` });
      console.log('error: ', err.message);
    },
    refetchQueries: () => ['getUsers'],
  });

  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER, {
    onCompleted: result => {
      if (password && willChangePassword) {
        changePassword({
          variables: {
            clientMutationId: generateUniqueId(),
            id: selectedUserId,
            password,
          },
        });
      }

      if (result && result.updateUser) {
        const districtIds = districts.map(d => d.districtId);

        updateUserAcf({
          variables: {
            userId: result.updateUser.user.userId,
            clientMutationId: generateUniqueId(),
            districts: districtIds,
          },
        });
      } else {
        setState({
          ...state,
          successMessage: 'Success! The user has been updated. ',
        });
      }
    },
    onError: err => {
      setState({ ...state, errorMessage: `Error! ${err.message}` });
      console.log('error: ', err.message);
    },
    refetchQueries: () => ['getUsers'],
  });

  const handleSelectUser = async userId => {
    setState({
      ...state,
      selectedUserId: userId,
      successMessage: '',
      errorMessage: '',
      userLoading: true,
    });
    const { data: userData } = await client.query({
      query: GET_USER_BY_ID,
      variables: {
        id: userId,
      },
    });

    if (userData && userData.user) {
      setState({
        ...state,
        selectedUserId: userId,
        email: userData.user.email,
        firstName: userData.user.firstName || '',
        lastName: userData.user.lastName || '',
        username: userData.user.username,
        role: userData.user.roles.edges.length
          ? userData.user.roles.edges[0].node.name
          : '',
        districts: userData.user.customFields.userToDistrict || [],
        userLoading: false,
        successMessage: '',
        errorMessage: '',
      });
    }
  };

  // Here in case we use id in the url
  useEffect(() => {
    if (id) {
      handleSelectUser(id);
      setState({ ...state, selectedUserId: id });
    }
  }, [id]);

  const handleSubmit = e => {
    e.preventDefault();
    if (formFunction === 'edit' && selectedUserId) {
      updateUser({
        variables: {
          clientMutationId: generateUniqueId(),
          id: selectedUserId,
          email,
          firstName,
          lastName,
          roles: [role],
        },
      });
    } else if (formFunction === 'create') {
      createUser({
        variables: {
          clientMutationId: generateUniqueId(),
          firstName,
          lastName,
          username,
          email,
          roles: [role],
          password,
        },
      });
    } else {
      alert('Please Select a user to edit');
    }
  };

  return (
    <EditUserPage>
      {formFunction === 'edit' && (
        <SelectUserSidebar
          loading={usersLoading}
          users={users}
          handleSelectUser={handleSelectUser}
          selectedValue={selectedUserId}
        />
      )}
      <Container>
        <main>
          {formFunction === 'edit' && (
            <h1>
              Edit
              {firstName && lastName ? (
                <span>
                  {firstName} {lastName}
                </span>
              ) : (
                ' User'
              )}
            </h1>
          )}

          {formFunction === 'create' && <h1>Create User</h1>}
          <FormContainer willChangePassword={willChangePassword}>
            <Form method="post" onSubmit={handleSubmit}>
              <div className="fieldset">
                <h3 className="full">Personal Info</h3>

                <label htmlFor="firstName" className="half">
                  First Name
                  <input
                    id="firstName"
                    type="text"
                    onChange={e =>
                      setState({ ...state, firstName: e.target.value })
                    }
                    value={firstName}
                    disabled={
                      loading || updateLoading || userLoading || acfLoading
                    }
                    required
                  />
                </label>
                <label htmlFor="lastName" className="half">
                  Last Name
                  <input
                    id="lastName"
                    type="text"
                    onChange={e =>
                      setState({ ...state, lastName: e.target.value })
                    }
                    value={lastName}
                    disabled={
                      loading || updateLoading || userLoading || acfLoading
                    }
                    required
                  />
                </label>
                <label htmlFor="username" className="half">
                  Username
                  <input
                    id="username"
                    type="text"
                    onChange={e =>
                      setState({ ...state, username: e.target.value })
                    }
                    value={username}
                    disabled={
                      loading ||
                      updateLoading ||
                      acfLoading ||
                      formFunction === 'edit'
                    }
                    required
                  />
                </label>
                <label htmlFor="email" className="half">
                  Email
                  <input
                    id="email"
                    type="email"
                    onChange={e =>
                      setState({ ...state, email: e.target.value })
                    }
                    value={email}
                    disabled={
                      loading || updateLoading || userLoading || acfLoading
                    }
                    required
                  />
                </label>
                <label htmlFor="password" className="half">
                  Password{' '}
                  {formFunction === 'edit' && (
                    <button
                      type="button"
                      id="password-change-btn"
                      onClick={() =>
                        setState({
                          ...state,
                          willChangePassword: !willChangePassword,
                        })
                      }
                    >
                      Update Password?
                    </button>
                  )}
                  <input
                    id="password"
                    type="text"
                    onChange={e =>
                      setState({ ...state, password: e.target.value })
                    }
                    value={password}
                    minLength="6"
                    disabled={
                      loading || updateLoading || userLoading || acfLoading
                    }
                    required={formFunction === 'create'}
                  />
                </label>
                <Select
                  title="Role"
                  className="half"
                  handleSelect={newRole =>
                    setState({ ...state, role: newRole })
                  }
                  selectedValue={role}
                  required
                >
                  <option value="advocate-delegate">Advocate - Delegate</option>
                  <option value="advocate-chapter-leader">
                    Advocate - Chapter Leader
                  </option>
                  <option value="employee">Employee</option>
                  <option value="employee-admin">Employee Admin</option>
                  <option value="admin">Admin</option>
                </Select>
              </div>

              <hr className="full" />

              <DistrictPicker
                districts={districts}
                updateDistricts={updatedDistricts =>
                  setState({ ...state, districts: updatedDistricts })
                }
                reload={reload}
              />

              {formFunction === 'edit' && selectedUserId && (
                <Button type="submit">
                  {loading || acfLoading || userLoading || updateLoading ? (
                    <FontAwesomeIcon icon="spinner" size="1x" spin />
                  ) : (
                    'Save Changes'
                  )}
                </Button>
              )}

              {formFunction === 'create' && (
                <Button type="submit">
                  {loading || acfLoading ? (
                    <FontAwesomeIcon icon="spinner" size="1x" spin />
                  ) : (
                    'Create User'
                  )}
                </Button>
              )}

              {successMessage && <Success message={successMessage} />}

              {errorMessage && <Error message={errorMessage} />}
            </Form>
          </FormContainer>
        </main>
      </Container>
    </EditUserPage>
  );
};

const EditUserPage = styled.div`
  display: flex;

  h1 span {
    color: ${props => props.theme.darkBlue};
    margin-left: 10px;
  }
`;

const Container = styled.div`
  padding: 0 5%;
  display: flex;

  aside {
    padding: 25px 0;
  }
`;

const FormContainer = styled.div`
  border-radius: 11px;
  box-shadow: ${props => props.theme.defaultBoxShadow};
  margin: 30px 0;

  #password {
    display: ${props => (props.willChangePassword ? 'block' : 'none')};
  }

  #password-change-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 2px;
    background: none;
    color: ${props => props.theme.lightText};
    font-size: 10px;
    text-decoration: underline;
  }
`;

UserForm.propTypes = {
  formFunction: PropTypes.string,
};

export default UserForm;
