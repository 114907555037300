import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';

import { useAuth } from '../../utilities/useAuth';
import { UPDATE_USER, CHANGE_PASSWORD } from '../../graphql/mutations';
import UserForm from '../../styles/UserForm';
import Error from '../../global/Error';
import Success from '../../global/Success';
import { generateUniqueId } from '../../utilities/generateUniqueId';
import Button from '../../global/Button';

const User = () => {
  const auth = useAuth();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(auth.user.email || '');
  const [firstName, setFirstName] = useState(auth.user.firstName || '');
  const [lastName, setLastName] = useState(auth.user.lastName || '');

  // mutation used to update the rest of the user from the /user screen
  const [
    updateUser,
    { loading: userLoading, error: userError, data: userSuccess },
  ] = useMutation(UPDATE_USER, {
    variables: {
      clientMutationId: generateUniqueId(),
      id: auth.user.id,
      firstName,
      lastName,
      email,
    },
    onCompleted: async result => {
      if (result && result.updateUser) {
        auth.setUser({
          ...auth.user,
          email: result.updateUser.user.email,
          firstName: result.updateUser.user.firstName,
          lastName: result.updateUser.user.lastName,
        });
      }
    },
  });

  const handleUpdateUser = e => {
    e.preventDefault();
    updateUser();
  };

  // mutation used to change the password of a user from the /user screen
  const [
    changePassword,
    { loading: passwordLoading, error: passwordError, data: passwordSuccess },
  ] = useMutation(CHANGE_PASSWORD, {
    variables: {
      clientMutationId: generateUniqueId(),
      id: auth.user.id,
      password,
    },
  });

  const handleChangePassword = e => {
    e.preventDefault();
    changePassword();
    setPassword('');
  };

  return (
    <Container>
      <main>
        <h1>User Profile</h1>
        <FormContainer>
          <UserForm method="post" onSubmit={handleChangePassword}>
            <div className="fieldset">
              <label htmlFor="password" className="full">
                New Password
                <input
                  id="password"
                  type="text"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                  minLength="6"
                  disabled={passwordLoading}
                  required
                />
              </label>
            </div>
            <Button type="submit">
              {passwordLoading ? (
                <FontAwesomeIcon icon="spinner" size="1x" spin />
              ) : (
                'Update Password'
              )}
            </Button>
            {passwordError && <Error message={passwordError.message} />}
            {passwordSuccess && (
              <Success message="Password was successfully changed..." />
            )}
          </UserForm>
        </FormContainer>

        <FormContainer>
          <UserForm method="post" onSubmit={handleUpdateUser}>
            <div className="fieldset">
              <label htmlFor="email" className="half">
                Email
                <input
                  id="email"
                  type="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  disabled={userLoading}
                  required
                />
              </label>
              <label htmlFor="firstName" className="half">
                First Name
                <input
                  id="firstName"
                  type="text"
                  onChange={e => setFirstName(e.target.value)}
                  value={firstName}
                  disabled={userLoading}
                  required
                />
              </label>
              <label htmlFor="lastName" className="half">
                Last Name
                <input
                  id="lastName"
                  type="lastName"
                  onChange={e => setLastName(e.target.value)}
                  value={lastName}
                  disabled={userLoading}
                  required
                />
              </label>
            </div>
            <Button type="submit">
              {userLoading ? (
                <FontAwesomeIcon icon="spinner" size="1x" spin />
              ) : (
                'Submit'
              )}
            </Button>
            {userError && <Error message={userError.message} />}
            {userSuccess && (
              <Success message="Your information was updated successfully..." />
            )}
          </UserForm>
        </FormContainer>
      </main>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 2%;
  margin: 0 auto;
  display: flex;
  max-width: 1500px;
`;

const FormContainer = styled.div`
  border-radius: 11px;
  box-shadow: ${props => props.theme.defaultBoxShadow};
  margin: 30px 0;
`;

export default User;
