import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import EventsCalendar from '../../global/EventsCalendar';
import Loader from '../../global/Loader';

const Events = () => {
  const { loading, error, data } = useQuery(GET_ALL_EVENTS);

  if (loading) return <Loader />;
  if (error) return 'Error!';

  const events = data.events.edges.map(event => {
    const obj = {
      title: event.node.title,
      start: event.node._EventStartDate,
      end: event.node._EventEndDate,
      allDay: event.node._EventAllDay,
      id: event.node.eventId,
    };
    return obj;
  });

  return (
    <div>
      <h3>AMAC Action Event Calendar</h3>
      {data && <EventsCalendar events={events} />}
    </div>
  );
};

const GET_ALL_EVENTS = gql`
  {
    events {
      edges {
        node {
          title
          eventId
          _EventStartDate
          _EventEndDate
          _EventDuration
          _EventAllDay
          _EventVenueID
        }
      }
    }
  }
`;

export default Events;
