import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Draggable } from 'react-beautiful-dnd'
import parser from 'react-html-parser'

import TitleInput from './TitleInput'
import EditBtns from './EditBtns'
import Confirm from '../../../global/Confirm'
import { useResources } from '../../../utilities/useResources'

const SidebarItem = ({ title, id, panelId, count, active, index, setActivePanel, toggleSidebar, setSearchTerm }) => {
	const [showDeleteConfirm, toggleDeleteConfirm] = useState(false)
	const [showLeaveConfirm, toggleLeaveConfirm] = useState(false)
	const [isDeleting, toggleDeleting] = useState(false)
	const [isUpdatingTitle, toggleUpdatingTitle] = useState(false)

	const resourceContext = useResources()
	const { isEditing, isReorderingPanels, deleteCollection, updateTitle, updatedPanelTitle } = resourceContext

	// when update panel title finishes, toggle updating title off
	useEffect(() => {
		if (isUpdatingTitle && updatedPanelTitle) {
			toggleUpdatingTitle(false)
		}
	}, [updatedPanelTitle])

	const handleUpdate = title => {
		updateTitle({
			panelId,
			title,
		})
	}

	const handleDelete = () => {
		if (!isDeleting) {
			toggleDeleting(true)
			deleteCollection({
				panelId,
			})
		}
	}

	const handlePanelSelect = () => {
		setSearchTerm("")
		toggleSidebar(false)
		if (isReorderingPanels) {
			toggleLeaveConfirm(true)
		} else {
			setActivePanel(id)
		}
	}

	return (
		<>
			<Draggable draggableId={`${id}`} index={index}>
				{provided => (
					<StyledListItem
						active={active}
						onClick={handlePanelSelect}
						ref={provided.innerRef}
						{...provided.draggableProps}
						isReordering={isReorderingPanels}
					>
						<div className='drag-handle' {...provided.dragHandleProps}>
							<FontAwesomeIcon icon='grip-vertical' />
						</div>
						<div className='left'>
							<div className='count'>{count}</div>
						</div>
						<div className='right'>
							{isEditing && isUpdatingTitle ? (
								<TitleInput currentTitle={title} submit={handleUpdate} cancel={toggleUpdatingTitle} />
							) : (
								<>
									<h3>{parser(title)}</h3>
									<EditBtns
										show={isEditing}
										toggleEdit={toggleUpdatingTitle}
										toggleConfirm={toggleDeleteConfirm}
										isDeleting={isDeleting}
										grey
									/>
								</>
							)}
						</div>
					</StyledListItem>
				)}
			</Draggable>

			{showDeleteConfirm && (
				<Confirm password={title} close={() => toggleDeleteConfirm(false)} callback={handleDelete}>
					<p>Are you sure you want to delete this panel and everything within it?</p>
				</Confirm>
			)}
			{showLeaveConfirm && (
				<Confirm close={() => toggleLeaveConfirm(false)} callback={() => setActivePanel(id)}>
					<p>Are you sure you want to leave this panel? Your reorder has not been saved.</p>
				</Confirm>
			)}
		</>
	)
}

const StyledListItem = styled.li`
	display: flex;
	align-items: center;
	color: ${props => (props.active ? '#fff' : 'inherit')};
	background: ${props => (props.active ? 'linear-gradient(#48ABF2, #62C3F4)' : '#fff')};
	box-shadow: ${props => (props.active ? '0 12px 15px rgba(72, 171, 242, 0.35)' : 'none')};
	z-index: ${props => (props.active ? 10 : 1)};
	position: relative;
	padding: 25px 15px;
	margin: 10px 0;
	cursor: pointer;

	&:hover {
		background: ${props => (props.active ? 'linear-gradient(#48ABF2, #62C3F4)' : 'rgba(196, 196, 196, 0.2)')};
	}

	.drag-handle {
		padding: ${props => (props.isReordering ? '0 10px' : '0')};
		width: ${props => (props.isReordering ? '35px' : '0')};
		overflow: hidden;
	}

	.left {
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: ${props => (props.active ? 'rgba(255, 255, 255, 0.25)' : 'rgba(196, 196, 196, 0.25)')};
		border-radius: 50%;
		margin-right: 15px;
	}

	.right {
		flex: 1;

		h3 {
			font-size: 16px;
		}
	}
`

export default SidebarItem
