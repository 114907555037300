import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Select = ({ handleSelect, title, className, children, loading, noDefault, selectedValue, required }) => {
	return (
		<FormLabel htmlFor={title} className={className}>
			{title}
			{loading && <Spinner icon='spinner' size='1x' spin />}
			<select
				id={title}
				name={title}
				required={required}
				value={selectedValue || ''}
				onChange={e => {
					handleSelect(e.target.value)
				}}
			>
				{!loading && (
					<option value='' disabled hidden>
						{!noDefault && `Choose ${title}...`}
					</option>
				)}
				{children}
			</select>
		</FormLabel>
	)
}

const FormLabel = styled.label`
	color: ${props => props.theme.lightText};
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 10px;
	margin-top: 10px;
	font-weight: bold;

	select {
		border: none;
		border-bottom: 2px solid ${props => props.theme.lightText};
		padding: 10px 25px;
		margin-top: 10px;
		font-size: 16px;
		-webkit-appearance: none;
		-moz-appearance: none;
		cursor: pointer;
		color: ${props => props.theme.lightText};
		transition: all 0.2s ${props => props.theme.animation};

		&::-ms-expand {
			display: none;
		}

		&:focus {
			outline: none;
			border-bottom: 2px solid ${props => props.theme.mediumBlue};
		}
	}

	&:after {
		content: '';
		height: 8px;
		width: 8px;
		background-color: transparent;
		border-bottom: 2px solid ${props => props.theme.lightText};
		border-right: 2px solid ${props => props.theme.lightText};
		position: absolute;
		top: 55%;
		transform: rotate(45deg);
		cursor: pointer;
		pointer-events: none;
		right: 20px;
	}
`

const Spinner = styled(FontAwesomeIcon)`
	color: ${props => props.theme.lightText};
	position: absolute;
	left: 16px;
	top: 55%;
`

Select.propTypes = {
	handleSelect: PropTypes.func,
	title: PropTypes.string,
	children: PropTypes.array,
	className: PropTypes.string,
	loading: PropTypes.bool,
}

export default Select
