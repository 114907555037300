import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loader from '../../global/Loader';
import createMarkup from '../../utilities/createMarkup';

const Rep = () => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_FULL_REP, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error: ${error.message}`;

  const {
    firstName,
    lastName,
    repImage,
    party,
    chamberOfCongress,
    repToDistrict,
    dateElected,
    nextElectionYear,
    generalDescription,
    background,
    committeeMembership,
    repToLegislation,
  } = data.representativeBy.customFields;

  return (
    <>
      <RepInfo>
        <h3>
          {chamberOfCongress} {firstName} {lastName}
        </h3>
        <img src={repImage.sourceUrl} alt={`Representative ${lastName}`} />
        <p>
          <b>Party</b>: {party}
        </p>
        <p>
          <b>District</b>:{' '}
          <Link
            to={`/districts/${repToDistrict[0].districtId}`}
            dangerouslySetInnerHTML={createMarkup(repToDistrict[0].title)}
          />
        </p>
        <p>
          <b>Date Elected</b>: {dateElected}
        </p>
        <p>
          <b>Next Election Year</b>: {nextElectionYear}
        </p>
        <h4>General Description</h4>
        <p dangerouslySetInnerHTML={createMarkup(generalDescription)} />
        <h4>Background</h4>
        <p dangerouslySetInnerHTML={createMarkup(background)} />
        <h4>Committee Membership</h4>
        <blockquote>
          {committeeMembership.map(com => (
            <>
              <p>
                <b>{com.committee}</b>
              </p>
              <ul>
                {com.positionsHeld &&
                  com.positionsHeld.map(pos => <li>{pos.positionTitle}</li>)}
              </ul>
            </>
          ))}
        </blockquote>
        <h4>Recent Legislation</h4>
        <ul>
          {repToLegislation.map(bill => {
            let excerpt = bill.content.slice(0, 100);
            excerpt = excerpt.length >= 100 ? `${excerpt}...` : excerpt;
            return (
              <li className="legislation-link">
                <div className="bill-title">{bill.title}</div>
                <div className="bill-desc">
                  -{' '}
                  <Link to={`/legislation/${bill.legislationId}`}>
                    <span dangerouslySetInnerHTML={createMarkup(excerpt)} />
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
      </RepInfo>
    </>
  );
};

const RepInfo = styled.div`
  img {
    max-width: 200px;
  }

  .bill-title {
    display: inline;
    text-decoration: none;
    color: ${props => props.theme.darkText};
  }

  .bill-desc {
    margin-left: 5px;
    display: inline-block;

    p {
      display: inline;
    }
  }
`;

const GET_FULL_REP = gql`
  query fullRep($id: Int!) {
    representativeBy(representativeId: $id) {
      id
      customFields {
        firstName
        lastName
        chamberOfCongress
        party
        dateElected
        nextElectionYear
        generalDescription
        background
        committeeMembership {
          committee
          positionsHeld {
            positionTitle
          }
        }
        repImage {
          sourceUrl
        }
        repToDistrict {
          ... on District {
            districtId
            title
          }
        }
        repToLegislation {
          ... on Legislation {
            legislationId
            title
            content
          }
        }
      }
    }
  }
`;

Rep.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default Rep;
