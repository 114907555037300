import React, { useState, useContext, createContext } from 'react';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';

import { GET_CURRENT_USER, PROTECTED_QUERY } from '../graphql/queries';
import {
  LOGIN_USER,
  REGISTER_USER,
  SEND_PASSWORD_RESET_EMAIL,
  RESET_PASSWORD,
} from '../graphql/mutations';
import { generateUniqueId } from './generateUniqueId';

const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);

const useProvideAuth = () => {
  const client = useApolloClient();
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(false);

  const roles = [
    'super-admin',
    'admin',
    'employee-admin',
    'employee',
    'advocate-chapter-leader',
    'advocate-delegate',
  ];

  // Lazy Query to get user info, used if user logged in via auth_token
  // ... throws user data into user state once returned

  const loadUser = async id => {
    setUserLoading(true);
    const { data: userData } = await client.query({
      query: GET_CURRENT_USER,
      variables: {
        id,
      },
    });
    if (userData && userData.user) {
      await setUser({
        ...userData.user,
        role: userData.user.roles.edges[0].node.name,
        roleIndex: roles.indexOf(userData.user.roles.edges[0].node.name),
      });
    }
    setUserLoading(false);
  };

  // Lazy Query to grab auth_token, if available, and authenticate it
  // ... runs on every re-render
  const { loading: protectedLoading } = useQuery(PROTECTED_QUERY, {
    onCompleted: result => {
      if (result && result.viewer && !user) {
        loadUser(result.viewer.id);
      }
    },
    onError: error => {
      window.localStorage.removeItem('auth_token');

      if (user || user === null) {
        setUser(false);
      }
      // }
    },
  });

  // Mutation to get new auth token if there is a refresh_token in local storage
  // const [refreshToken, { data: refreshData }] = useMutation(REFRESH_TOKEN, {
  //   onCompleted: result => {
  //     if (result && result.refreshJwtAuthToken.authToken) {
  //       console.log('setting new auth');
  //       window.localStorage.setItem(
  //         'auth_token',
  //         result.refreshJwtAuthToken.authToken
  //       );
  //     }
  //   },
  //   onError: error => {
  //     console.log('refresh token error: ', error);
  //     window.localStorage.removeItem('auth_token');
  //     window.localStorage.removeItem('refresh_token');
  //   },
  // });

  // Mutation used to sign user in with WP username and password
  // ... throws user data into user state once returned
  const [loginUser, { loading: loginLoading, error: loginError }] = useMutation(
    LOGIN_USER,
    {
      onCompleted: result => {
        if (result && result.login.user.id) {
          window.localStorage.setItem('auth_token', result.login.authToken);
          // window.localStorage.setItem(
          //   'refresh_token',
          //   result.login.refreshToken
          // );

          setUser({
            ...result.login.user,
            jwtAuthToken: result.login.authToken,
            role: result.login.user.roles.edges[0].node.name,
            roleIndex: roles.indexOf(
              result.login.user.roles.edges[0].node.name
            ),
          });
        }
      },
      onError: error => {
        console.log('login error: ', error.message);
      },
    }
  );

  // Mutation used to sign a user up (create WP user) with username, email, and password
  const [
    signupUser,
    { loading: signupLoading, error: signupError },
  ] = useMutation(REGISTER_USER, {
    onError: error => new Error(error.message),
  });

  // Mutation use to send password reset email to user who's forgotten their password
  const [
    sendPasswordReset,
    { loading: emailLoading, error: emailError, data: emailSuccess },
  ] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  // function to call the loginUser Mutation, passed down to UI via context
  const login = (username, password) => {
    const clientMutationId = generateUniqueId();
    loginUser({
      variables: {
        username,
        password,
        clientMutationId,
      },
    });
  };

  // function to call the signupUser mutation, passed down to UI via context
  // ... automatically signs the user in once signupUser mutation is completed
  const signup = async (username, email, password) => {
    await signupUser({
      variables: {
        username,
        email,
        password,
      },
    });

    login(username, password);
  };

  const logout = () => {
    setUser(false);
    window.localStorage.removeItem('auth_token');
    client.resetStore();
    // window.localStorage.removeItem('refresh_token');
  };

  const sendPasswordResetEmail = username => {
    const clientMutationId = generateUniqueId();
    sendPasswordReset({
      variables: {
        username,
        clientMutationId,
      },
    });
  };

  const [
    resetPass,
    { loading: resetLoading, error: resetError, data: resetSuccess },
  ] = useMutation(RESET_PASSWORD);

  const resetPassword = (key, userLogin, password) => {
    const clientMutationId = generateUniqueId();

    resetPass({
      variables: {
        clientMutationId,
        key,
        login: userLogin,
        password,
      },
    });
  };

  return {
    user,
    setUser,
    userLoading,
    protectedLoading,
    login,
    loginLoading,
    loginError,
    signup,
    signupLoading,
    signupError,
    sendPasswordResetEmail,
    emailLoading,
    emailError,
    emailSuccess,
    logout,
    resetPassword,
    resetLoading,
    resetError,
    resetSuccess,
  };
};
