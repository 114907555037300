import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import EventMap from '../../global/EventMap';
import EventDetails from './EventDetails';
import Loader from '../../global/Loader';

const Event = ({ match }) => {
  const [getVenue, { data: venueData }] = useLazyQuery(GET_VENUE);

  const { loading, error, data } = useQuery(GET_EVENT, {
    variables: {
      id: parseInt(match.params.id),
    },
    onCompleted: result => {
      getVenue({ variables: { id: parseInt(result.eventBy._EventVenueID) } });
    },
  });

  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;

  const {
    title,
    featuredImage,
    content,
    _EventAllDay,
    _EventStartDate,
    _EventEndDate,
    customFields,
  } = data.eventBy;

  return (
    <EventPage>
      <img
        className="featured-image"
        src={
          featuredImage
            ? featuredImage.sourceUrl
            : 'http://dashboard.amacaction.org/wp-content/uploads/2019/08/capitol-building-night-1.jpg'
        }
        alt={`${title} Featured`}
      />
      <h1>{title}</h1>
      <div className="row">
        <div className="details">
          <EventDetails
            content={content}
            allDay={_EventAllDay}
            start={_EventStartDate}
            end={_EventEndDate}
            districts={customFields.eventsToDistrict}
          />
        </div>
        <div className="map">
          {venueData && venueData.venueBy ? (
            <EventMap
              coords={{
                lat: venueData.venueBy._VenueLat,
                lng: venueData.venueBy._VenueLng,
              }}
              title={venueData.venueBy.title}
            />
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </EventPage>
  );
};

// ---------- Styles --------------- //

const EventPage = styled.div`
  .featured-image {
    max-width: 100%;
    width: 100%;
    height: auto;
    max-height: 350px;
    object-fit: cover;
  }

  .details,
  .map {
    width: 48%;
    position: relative;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }
`;

// ---------- Queries -------------- //

const GET_EVENT = gql`
  query getEvent($id: Int!) {
    eventBy(eventId: $id) {
      title
      content
      featuredImage {
        sourceUrl
        srcSet
      }
      _EventStartDate
      _EventEndDate
      _EventAllDay
      _EventVenueID
      customFields {
        eventsToDistrict {
          ... on District {
            title
            districtId
            customFields {
              repToDistrict {
                ... on Representative {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_VENUE = gql`
  query getVenue($id: Int!) {
    venueBy(venueId: $id) {
      title
      _VenueLat
      _VenueLng
    }
  }
`;

// ------- End Queries -------- //

Event.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default Event;
