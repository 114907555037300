import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const EventsCalendar = withRouter(({ events, history }) => {
  const toEvent = event => {
    history.push(`/events/${event.id}`);
  };

  return (
    <div style={{ height: '300px' }}>
      <Calendar
        localizer={localizer}
        events={events}
        onSelectEvent={toEvent}
        tooltipAccessor="Thing to do"
      />
    </div>
  );
});

export default EventsCalendar;
