import styled from 'styled-components'

const SquaredButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${props =>
		props.save
			? props.theme.accentMint
			: props.cancel
			? props.theme.mediumRed
			: props.orange
			? props.theme.mediumOrange
			: props.theme.lightBlueTwo};
	color: #fff;
	font-weight: bold;
	font-size: 14px;
	padding: 12px 32px;
	border: none;
	cursor: pointer;
	transition: all 0.25s ${props => props.theme.animation};
	margin-left: 10px;

	span {
		margin-right: 10px;
	}

	svg {
		margin-bottom: 2px;
	}

	&:hover {
		opacity: 0.8;
	}

	&:focus {
		outline: none;
	}
`

export default SquaredButton
