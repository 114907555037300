/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { useAuth } from '../../../utilities/useAuth'
import { getTodayFormatted } from '../../../utilities/getToday'
import ResourceModal from '../../../styles/ResourceModal'
import theme from '../../../styles/Theme'
import { useResources } from '../../../utilities/useResources'
import Button from '../../../global/Button'

const EditResource = ({ resource, toggleOpen }) => {
	const auth = useAuth()
	const resources = useResources()
	const [removeDateUpdated, setRemoveDateUpdated] = useState(false)
	const [removeDateAdded, setRemoveDateAdded] = useState(false)
	const [file, setFile] = useState('')
	const [type, setType] = useState(resource.type || '')
	const [filePreview, setFilePreview] = useState(resource.previewSrc || '')
	const [title, setTitle] = useState(resource.title || '')
	const [link, setLink] = useState(resource.link || '')
	const [uploadingFile, setUploadingFile] = useState(false)
	const fileInput = useRef(null)

	const addFile = e => {
		if (e.target.files) {
			setFile(e.target.files[0])

			if (e.target.files[0].type.includes('image')) {
				const src = window.URL.createObjectURL(e.target.files[0])
				setFilePreview(src)
				setType('')
			} else {
				setType('pdf')
			}
		}
	}

	const removeFile = () => {
		fileInput.current.value = ''
		setFile('')
		setFilePreview('')
	}

	const handleUpload = e => {
		e.preventDefault()

		if (!file && !filePreview && !link) {
			alert('You must include either a file or a link.')
			setUploadingFile(false)
			return
		}

		if (file) {
			setUploadingFile(true)

			const endpoint = `${process.env.REACT_APP_GRAPHQL_URI}/wp-json/wp/v2/media`

			const formData = new FormData()
			formData.append('file', file)
			const params = {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${auth.user.jwtAuthToken}`,
				},
				body: formData,
				method: 'POST',
			}

			fetch(endpoint, params)
				.then(data => data.json())
				.then(res => {
					resources.updateResource(
						resource.panelId,
						resource.groupIndex,
						resource.subgroupIndex,
						resource.resourceIndex,
						{
							id: resource.id,
							link,
							pdf: {
								databaseId: res.id,
								mediaItemUrl: res.source_url,
							},
							title,
							dateupdated: removeDateUpdated ? '19890420' : getTodayFormatted(),
							dateadded: removeDateAdded ? '19890420' : resource.dateadded,
						}
					)
					setUploadingFile(false)
				})
				.catch(error => {
					console.log(error)
					setUploadingFile(false)
				})
		} else if (link) {
			resources.updateResource(
				resource.panelId,
				resource.groupIndex,
				resource.subgroupIndex,
				resource.resourceIndex,
				{
					id: resource.id,
					link,
					pdf: 0,
					title,
					dateupdated: removeDateUpdated ? '19890420' : getTodayFormatted(),
					dateadded: removeDateAdded ? '19890420' : resource.dateadded,
				}
			)
		} else if (title !== resource.title) {
			resources.updateResource(
				resource.panelId,
				resource.groupIndex,
				resource.subgroupIndex,
				resource.resourceIndex,
				{
					id: resource.id,
					link,
					pdf: {
						databaseId: resource.fileId,
						mediaItemUrl: resource.previewSrc,
					},
					title,
					dateupdated: removeDateUpdated ? '19890420' : getTodayFormatted(),
					dateadded: removeDateAdded ? '19890420' : resource.dateadded,
				}
			)
		} else {
			resources.updateResource(
				resource.panelId,
				resource.groupIndex,
				resource.subgroupIndex,
				resource.resourceIndex,
				{
					id: resource.id,
					link,
					pdf: {
						databaseId: resource.fileId,
						mediaItemUrl: resource.previewSrc,
					},
					title,
					dateupdated: removeDateUpdated ? '19890420' : getTodayFormatted(),
					dateadded: removeDateAdded ? '19890420' : resource.dateadded,
				}
			)
		}
	}

	return (
		<ResourceModal>
			<div className='form-container'>
				{type === 'pdf' && <FontAwesomeIcon className='type' icon='file-pdf' />}
				{(type === 'ppt' || type === 'pptx') && <FontAwesomeIcon className='type' icon='file-powerpoint' />}
				{type !== 'pdf' && type !== 'ppt' && type !== 'pptx' && filePreview && (
					<img src={filePreview} alt='Upload Preview' />
				)}
				<form onSubmit={handleUpload}>
					<div className='head'>
						<h2>Edit Resource</h2>
						<button type='button' onClick={() => toggleOpen(false)} title='close'>
							<FontAwesomeIcon icon={['far', 'window-close']} />
						</button>
					</div>
					<label htmlFor='title'>
						Title
						<input type='text' onChange={e => setTitle(e.target.value)} value={title} required />
					</label>
					<label htmlFor='file-upload' className='file-upload-label'>
						File{' '}
						{resource.previewSrc && (
							<small>
								<i>
									(current resource:{' '}
									<a href={resource.previewSrc} target='_blank' rel='noopener noreferrer'>
										{resource.previewSrc}
									</a>
									)
								</i>
							</small>
						)}
						<input id='file-upload' type='file' onChange={addFile} ref={fileInput} />
					</label>
					{file && (
						<button id='remove-file' type='button' onClick={removeFile}>
							Remove File
						</button>
					)}
					<label htmlFor='link'>
						<span>
							Link{' '}
							<small>
								<i>(to upload an external link instead of a file)</i>
							</small>
						</span>
						<input type='url' onChange={e => setLink(e.target.value)} value={link} />
					</label>

					<label htmlFor='remove-date-updated' className='remove-date-updated'>
						<input type='checkbox' onChange={() => setRemoveDateUpdated(!removeDateUpdated)} value={true} />
						<small>Remove/don't include 'updated' flag.</small>
					</label>

					<label htmlFor='remove-date-updated' className='remove-date-updated'>
						<input type='checkbox' onChange={() => setRemoveDateAdded(!removeDateAdded)} value={true} />
						<small>Remove/don't include 'added' asterisk.</small>
					</label>

					<div className='button-row'>
						<Button type='submit'>
							{uploadingFile || resources.updating ? (
								<FontAwesomeIcon icon='spinner' size='1x' spin />
							) : (
								'Save Resource'
							)}
						</Button>
						<Button handleClick={() => toggleOpen(false)} color={theme.mediumRed}>
							Cancel
						</Button>
					</div>
				</form>
			</div>
		</ResourceModal>
	)
}

EditResource.propTypes = {
	resource: PropTypes.shape({
		type: PropTypes.string,
		previewSrc: PropTypes.string,
		title: PropTypes.string,
		link: PropTypes.string,
		panelId: PropTypes.number,
		groupIndex: PropTypes.number,
		subgroupIndex: PropTypes.number,
		resourceIndex: PropTypes.number,
		fileId: PropTypes.number,
	}),
	toggleOpen: PropTypes.func,
}

export default EditResource
