/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Droppable, DragDropContext } from 'react-beautiful-dnd'

import { useAuth } from '../../../utilities/useAuth'
import { useResources } from '../../../utilities/useResources'
import { reorderPanels } from '../../../utilities/newReorder'

import SidebarItem from './SidebarItem'
import AddGroup from './AddGroup'
import Fab from '../../../styles/Fab'
import SquaredButton from '../../../styles/SquaredButton'

const Sidebar = ({searchTerm, setSearchTerm}) => {
	const [addingPanel, setAddingPanel] = useState(false)
	const [showSidebar, toggleSidebar] = useState(false)

	const resources = useResources()
	const {
		panelAdded,
		isEditing,
		isReordering,
		isReorderingPanels,
		toggleReorderingPanels,
		saveReorderedPanels,
		savingPanelsReorder,
		addCollection,
		initialSidebarPanels,
		currentPanel,
		currentPanelId,
		setCurrentPanelId,
		reorderedSidebarPanels,
		setReorderedSidebarPanels,
	} = resources

	const auth = useAuth()

	// const handlePanelOnClick = () =>{
	// 	setSearchTerm("")
	// }

	useEffect(() => {
		if (isReorderingPanels) {
			setReorderedSidebarPanels(initialSidebarPanels)
		} else {
			setReorderedSidebarPanels([])
		}
	}, [isReorderingPanels])

	const getResourceCount = panel => {
		let count = 0

		if (panel.id === currentPanelId && isEditing) {
			count = currentPanel.groups
				? currentPanel.groups.reduce((total, group) => {
						let groupTotal = 0
						if (group.subgroups) {
							groupTotal = group.subgroups.reduce(
								(subTotal, subgroup) => subTotal + (subgroup.resources ? subgroup.resources.length : 0),
								0
							)
						}
						return total + groupTotal
				  }, 0)
				: 0
		} else {
			count = panel.customFields.groups
				? panel.customFields.groups.reduce((total, group) => {
						let groupTotal = 0
						if (group.subgroups) {
							groupTotal = group.subgroups.reduce(
								(subTotal, subgroup) => subTotal + (subgroup.resources ? subgroup.resources.length : 0),
								0
							)
						}
						return total + groupTotal
				  }, 0)
				: 0
		}
		return count
	}

	const saveNewPanel = title => {
		addCollection({ title, index: initialSidebarPanels.length + 1 })
	}

	const handleSaveReorderedPanels = () => {
		saveReorderedPanels(reorderedSidebarPanels)
	}

	useEffect(() => {
		if (addingPanel && panelAdded) {
			setAddingPanel(false)
		}
	}, [panelAdded])

	const onDragEnd = result => {
		const newOrder = reorderPanels(result, reorderedSidebarPanels)
		setReorderedSidebarPanels(newOrder)
	}



	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<SidebarToggle className='sidebar-toggle' onClick={() => toggleSidebar(!showSidebar)} sidebarShown={showSidebar}>
				<FontAwesomeIcon icon='plus' />
			</SidebarToggle>
			<StyledSidebar show={showSidebar}>
				<div className='sidebar-header'>
					<h1>Resource Library</h1>
					{auth.user.roleIndex <= 2 && !isReordering && !isEditing && (
						<div className='btn-group'>
							<SquaredButton onClick={toggleReorderingPanels} cancel={isReorderingPanels} orange={!isReorderingPanels}>
								<span>{!isReorderingPanels ? 'Reorder Panels' : 'Cancel'}</span>
								{isReorderingPanels ? (
									<FontAwesomeIcon icon={['far', 'window-close']} />
								) : (
									<FontAwesomeIcon icon='random' />
								)}
							</SquaredButton>

							{isReorderingPanels && (
								<SquaredButton onClick={handleSaveReorderedPanels} save>
									<span>Save</span>
									{savingPanelsReorder ? <FontAwesomeIcon icon='spinner' spin /> : <FontAwesomeIcon icon='random' />}
								</SquaredButton>
							)}
						</div>
					)}
				</div>
				<Droppable droppableId='panels-sidebar' type='panels'>
					{provided => (
						<ul className='panel-list' ref={provided.innerRef} {...provided.droppableProps}>
							{isReorderingPanels && reorderedSidebarPanels.length
								? reorderedSidebarPanels.map((panel, index) => {
										const count = getResourceCount(panel)

										return (
											<SidebarItem
												title={panel.title}
												id={panel.id}
												panelId={panel.resourcePanelId}
												count={count}
												active={panel.id === currentPanelId && searchTerm.length < 3 ? 'active' : ''}
												index={index}
												setActivePanel={setCurrentPanelId}
												key={panel.id}
												setSearchTerm = {setSearchTerm}
											/>
										)
								  })
								: initialSidebarPanels.map((panel, index) => {
										const count = getResourceCount(panel)

										return (
											<SidebarItem
												title={panel.title}
												id={panel.id}
												panelId={panel.resourcePanelId}
												count={count}
												active={panel.id === currentPanelId && searchTerm.length < 3  ? 'active' : ''}
												index={index}
												setActivePanel={setCurrentPanelId}
												key={panel.id}
												toggleSidebar={toggleSidebar}
												setSearchTerm = {setSearchTerm}
											/>
										)
								  })}
							{provided.placeholder}
						</ul>
					)}
				</Droppable>

				{isEditing &&
					(addingPanel ? (
						<AddGroup level='Panel' handleShow={setAddingPanel} handleSubmit={saveNewPanel} />
					) : (
						<Fab>
							<button className='add-collection-btn' onClick={() => setAddingPanel(true)}>
								<FontAwesomeIcon icon='plus' />
								<p>Add Panel</p>
							</button>
						</Fab>
					))}
			</StyledSidebar>
		</DragDropContext>
	)
}

const SidebarToggle = styled.button`
display: none;
	cursor: pointer;
	position: fixed;
	left: ${props => (props.sidebarShown ? '275px' : '-25px')};
	top: 115px;
	z-index: 100;
	width: 66px;
	text-align: right;
	color: #fff;
	font-size: 26px;
	background: linear-gradient(#48abf2, #62c3f4);
	box-shadow: ${props => props.theme.defaultBoxShadow};
	border: none;
	border-radius: 50px;
	padding: 8px 8px;
	transition: all 0.25s ${props => props.theme.animation};

	svg {
		transform: rotate(${props => (props.sidebarShown ? '315deg' : '0deg')});
		transition: all 0.25s ${props => props.theme.animation};
	}

	&:focus {
		outline: none;
	}

	@media (max-width: 767px) {
		display: block;
	}
`

const StyledSidebar = styled.aside`
	height: calc(100vh - 100px);
	width: 28%;
	max-width: 400px;
	background: #fff;
	box-shadow: 4px 0 20px rgba(89, 89, 89, 0.1);
	position: sticky;
	left: 0;
	top: 100px;
	overflow-y: scroll;
	z-index: 101;
	padding-bottom: 15px;
	transition: all 0.25s ${props => props.theme.animation};

	.sidebar-header {
		padding: 15px;
		position: sticky;
		top: 0;
		background: #fff;
		box-shadow: 0 2px 20px rgba(89, 89, 89, 0.2);
		z-index: 100;

		h1 {
			font-size: 30px;
			text-align: center;
		}

		.btn-group {
			display: flex;
			justify-content: space-around;
			align-items: center;
		}
	}

	.panel-list {
		list-style: none;
		padding: 0 15px;
	}

	@media (max-width: 767px) {
		position: fixed;
		height: 100%;
		width: 300px;
		top: 0;
		left: ${props => (props.show ? '0%' : '-300px')};
		box-shadow: ${props => props.theme.defaultBoxShadow};
	}
`

export default Sidebar
