import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Button = ({ handleClick, color, disabled, type, darkText, children }) => {
	return (
		<StyledButton type={type || 'button'} onClick={handleClick} color={color} darkText={darkText} disabled={disabled}>
			{children}
		</StyledButton>
	)
}

const StyledButton = styled.button`
	background: ${props => (props.color ? props.color : props.theme.mediumBlue)};
	padding: 12px 16px;
	font-size: 14px;
	font-weight: bold;
	cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
	opacity: ${props => (props.disabled ? '.5' : '1')};
	color: ${props => (props.darkText ? props.theme.darkText : '#fff')};
	box-shadow: ${props => props.theme.liBoxShadow};
	transition: all 0.2s ${props => props.theme.animation};
	border: none;
	border-radius: 8px;

	svg {
		margin-left: 8px;

		&[data-icon='spinner'] {
			margin-left: 0;
		}
	}

	&:hover {
		box-shadow: ${props => props.theme.liBoxShadowHovered};
		transform: translateY(-2px);
	}
`

Button.propTypes = {
	handleClick: PropTypes.func,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	children: PropTypes.node.isRequired,
}

export default Button
