import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import parse from 'react-html-parser'

const TitleInput = ({ currentTitle, submit, cancel }) => {
	const [title, setTitle] = useState(parse(currentTitle))
	const [isSaving, toggleSaving] = useState(false)

	const handleSubmit = e => {
		e.preventDefault()
		if (isSaving) return
		if (title) {
			toggleSaving(true)
			submit(title)
		} else {
			alert('Please make sure the title is filled out and try again.')
		}
	}

	const handleCancel = e => {
		e.stopPropagation()
		if (isSaving) return
		cancel()
	}

	return (
		<Form onSubmit={handleSubmit}>
			<input
				onChange={e => setTitle(e.target.value)}
				value={title}
				onClick={e => e.stopPropagation()}
				disabled={isSaving}
			/>
			<button type='submit' className='save-btn' title='Save Subgroup Title' onClick={e => e.stopPropagation()}>
				{isSaving ? <FontAwesomeIcon icon='spinner' size='xs' spin /> : <FontAwesomeIcon icon={['far', 'save']} />}
			</button>
			<button onClick={handleCancel} className='close-btn' title='Cancel'>
				<FontAwesomeIcon icon={['far', 'window-close']} />
			</button>
		</Form>
	)
}

export default TitleInput

const Form = styled.form`
	width: 100%;

	input {
		margin-right: 15px;
		padding: 5px;
		width: 50%;
		min-width: 150px;
	}

	button {
		cursor: pointer;
		border: none;
		background: none;
		color: ${props => props.theme.lightText};
		padding: 7px;
		transition: all 0.25s ${props => props.theme.animation};

		&.save-btn:hover {
			background: ${props => props.theme.accentMint};
			color: #fff;
		}

		&.close-btn:hover {
			background: ${props => props.theme.mediumOrange};
			color: #fff;
		}
	}
`
