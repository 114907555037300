import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PasswordInput = ({ label, changePassword, password }) => {
  const [showPassword, toggleShowPassword] = useState(false);

  return (
    <label htmlFor="password">
      {label}
      <span>
        {!showPassword ? (
          <FontAwesomeIcon
            icon="eye"
            onClick={() => toggleShowPassword(true)}
            title="Show password"
          />
        ) : (
          <FontAwesomeIcon
            icon="eye-slash"
            onClick={() => toggleShowPassword(false)}
            title="Hide password"
          />
        )}
      </span>
      <input
        id="password"
        className="pass"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={e => changePassword(e.target.value)}
        required
      />
    </label>
  );
};

export default PasswordInput;
