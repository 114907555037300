import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from 'styled-components'
import { HashRouter as Router } from 'react-router-dom'

import {
	faSpinner,
	faChevronRight,
	faMapPin,
	faFilePdf,
	faFileExcel,
	faFilePowerpoint,
	faFileImage,
	faLink,
	faExternalLinkAlt,
	faUserPlus,
	faUserEdit,
	faFile,
	faFileUpload,
	faSort,
	faGripVertical,
	faEye,
	faEyeSlash,
	faLayerGroup,
	faQuestionCircle,
	faAsterisk,
	faFlag,
	faPlus,
	faRandom,
} from '@fortawesome/free-solid-svg-icons'

import { faEdit, faWindowClose, faTrashAlt, faSave } from '@fortawesome/free-regular-svg-icons'
import { AuthProvider } from './utilities/useAuth'

import theme from './styles/Theme'
import AppRouter from './AppRouter'

import './App.css'
import { ResourcesProvider } from './utilities/useResources'

// add all Font Awesome Icons here in order to use anywhere in app
library.add(
	faSpinner,
	faChevronRight,
	faMapPin,
	faFilePdf,
	faFileExcel,
	faFilePowerpoint,
	faFileImage,
	faLink,
	faExternalLinkAlt,
	faUserPlus,
	faUserEdit,
	faFile,
	faFileUpload,
	faEdit,
	faSave,
	faSort,
	faGripVertical,
	faEye,
	faEyeSlash,
	faTrashAlt,
	faWindowClose,
	faLayerGroup,
	faQuestionCircle,
	faAsterisk,
	faFlag,
	faPlus,
	faRandom
)

const client = new ApolloClient({
	uri: `${process.env.REACT_APP_GRAPHQL_URI}/graphql`,
	request: operation => {
		const token = window.localStorage.getItem('auth_token')

		operation.setContext({
			headers: {
				Authorization: token ? `Bearer ${token}` : '',
			},
		})
	},
})

function App() {
	return (
		<ApolloProvider client={client}>
			<ThemeProvider theme={theme}>
				<AuthProvider>
					<CookiesProvider>
						<ResourcesProvider>
							<Router basename='/'>
								<AppRouter />
							</Router>
						</ResourcesProvider>
					</CookiesProvider>
				</AuthProvider>
			</ThemeProvider>
		</ApolloProvider>
	)
}

export default App
