import gql from 'graphql-tag'

export const GET_DISTRICTS = gql`
  query getDistricts($id: ID!) {
    stateBy(id: $id) {
      customFields {
        stateToDistricts {
          ... on District {
            title
            districtId
            customFields {
              repToDistrict {
                ... on Representative {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_STATES = gql`
  {
    states(first: 50, where: { orderby: { field: TITLE, order: ASC } }) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;