import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import logo from '../../assets/img/amac-action-logo.png';
import Login from './Login';
import Disclaimer from './Disclaimer';

import AuthContainer from '../../styles/AuthContainer';

const Auth = ({ disclaimer }) => {

  return (
    <AuthContainer>
      <div className="top">
        <img src={logo} alt="AMAC Action Logo" />
        <h3>
          Member Area<span>{disclaimer || 'login'}</span>
        </h3>
      </div>
      <div className="form-section">
        {disclaimer ? <Disclaimer /> : <Login />}
      </div>
      <VolunteerDisclaimer>
        <hr />
        <p>
          <b>This is for AMAC Action Employees and Volunteers only.</b>
        </p>
        <p>
          If you would like to register or learn more about the AMAC Action
          program please visit{' '}
          <a href="https://amacaction.org" target="_blank" rel="noreferrer">
            AMACAction.org
          </a>
        </p>
        <hr />
      </VolunteerDisclaimer>
    </AuthContainer>
  );
};

const VolunteerDisclaimer = styled.div`
  text-align: center;
  margin-top: 40px;
  padding: 0 15px;

  hr {
    width: 60%;
  }
`;

Auth.propTypes = {
  setEasyPass: PropTypes.func,
};

export default Auth;
