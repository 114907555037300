import gql from 'graphql-tag';

export const ADD_GROUP = gql`
  mutation addGroup(
    $clientMutationId: String!
    $panelId: Int!
    $id: String!
    $title: String!
  ) {
    addGroup(
      input: {
        clientMutationId: $clientMutationId
        panelId: $panelId
        id: $id
        title: $title
      }
    ) {
      panelId
      id
      title
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup(
    $clientMutationId: String!
    $panelId: Int!
    $groupIndex: Int!
  ) {
    deleteGroup(
      input: {
        clientMutationId: $clientMutationId
        panelId: $panelId
        groupIndex: $groupIndex
      }
    ) {
      panelId
      groupIndex
    }
  }
`;

export const UPDATE_GROUP_TITLE = gql`
  mutation updateGroupTitle(
    $clientMutationId: String!
    $panelId: Int!
    $groupIndex: Int!
    $title: String!
  ) {
    updateGroupTitle(
      input: {
        clientMutationId: $clientMutationId
        panelId: $panelId
        groupIndex: $groupIndex
        title: $title
      }
    ) {
      panelId
      groupIndex
      title
    }
  }
`;