import styled from 'styled-components'

const AddGroupBtns = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	button {
		background: #fff;
		padding: 12px 0;
		cursor: pointer;
		color: ${props => props.theme.lightText};
		font-size: 16px;
		box-shadow: ${props => props.theme.liBoxShadow};
		transition: all 0.2s ${props => props.theme.animation};
		width: 49%;
		border: none;

		svg {
			color: rgba(0, 0, 0, 0.25);
			margin-left: 10px;
		}

		&:hover {
			box-shadow: ${props => props.theme.liBoxShadowHovered};
		}
	}

	.save-reorder-btn {
		background: ${props => props.theme.mediumOrange};
		color: #fff;
	}
`

export default AddGroupBtns
