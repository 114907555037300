import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { useAuth } from '../../utilities/useAuth';
import Select from '../../global/Select';

const SelectUserSidebar = ({
  handleSelectUser,
  selectedValue,
  loading,
  users,
}) => {
  const auth = useAuth();

  const animation = useSpring({
    transform: 'translateX(0)',
    from: { transform: 'translateX(-100%)' },
  });

  const roles = [
    'super-admin',
    'admin',
    'employee-admin',
    'employee',
    'advocate-chapter-leader',
    'advocate-delegate',
  ];

  const sortNames = (person1, person2) => {
    const firstUser =
      person1.node.firstName || person1.node.lastName || person1.node.username;
    const secondUser =
      person2.node.firstName || person2.node.lastName || person2.node.username;
    return firstUser.toUpperCase() < secondUser.toUpperCase() ? -1 : 1;
  };

  const filterUsers = user => {
    if (user.node.roles.edges.length) {
      return (
        roles.indexOf(user.node.roles.edges[0].node.name) >= auth.user.roleIndex
      );
    }
    return true;
  };

  return (
    <Aside style={animation}>
      <Select
        title="Select a User to Edit"
        handleSelect={handleSelectUser}
        selectedValue={selectedValue}
        loading={loading}
        noDefault
      >
        {users &&
          users
            .filter(filterUsers)
            .sort(sortNames)
            .map(user => (
              <option key={user.node.id} value={user.node.id}>
                {user.node.firstName && user.node.lastName
                  ? `${user.node.firstName} ${user.node.lastName}`
                  : user.node.username}
              </option>
            ))}
      </Select>
    </Aside>
  );
};

const Aside = styled(animated.aside)`
  padding: 30px;
  min-height: calc(100vh - 65px);
  background-color: #eaeae9;
  box-shadow: ${props => props.theme.defaultBoxShadow};

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 10px 0;
    }
    button {
      background: #fff;
      border: none;
      border-radius: 20px;
      padding: 10px 12px;
      width: 100%;
      text-align: left;
      cursor: pointer;
      color: ${props => props.theme.darkText};

      &:hover {
        color: ${props => props.theme.mediumBlue};
        background: ${props => props.theme.lightBlue};
      }
    }
  }
`;

export default SelectUserSidebar;
