import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Loader = ({ size, min }) => <Spinner size={size} small={min} />;

const Spinner = styled.div`
  border-radius: 50%;
  border: none;
  border-top: 6px solid ${props => props.theme.lightRed};
  width: ${props => props.size || '100px'};
  height: ${props => props.size || '100px'};
  position: ${props => (props.small ? 'relative' : 'absolute')};
  top: ${props => (props.small ? 'auto' : '0')};
  left: ${props => (props.small ? 'auto' : '0')};
  right: ${props => (props.small ? 'auto' : '0')};
  bottom: ${props => (props.small ? 'auto' : '0')};
  margin: auto;
  animation: spin 1s infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

Loader.propTypes = {
  size: PropTypes.string,
  min: PropTypes.bool,
};

export default Loader;
