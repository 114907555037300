/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Draggable, Droppable } from "react-beautiful-dnd";
import parse from "react-html-parser";
import cloneDeep from "lodash/cloneDeep";

import Subgroup from "./Subgroup";
import AddGroup from "./AddGroup";
import { useResources } from "../../../utilities/useResources";
import TitleInput from "./TitleInput";
import EditBtns from "./EditBtns";
import Fab from "../../../styles/Fab";
import SquaredButton from "../../../styles/SquaredButton";
import Confirm from "../../../global/Confirm";
import Modal from "../../../global/Modal";

const Group = ({ panelId, groupIndex, group }) => {
	const [addingSubgroup, setAddingSubgroup] = useState(false);
	const [isUpdatingTitle, toggleUpdatingTitle] = useState(false);
	const [isDeleting, toggleDeleting] = useState(false);
	const [showConfirm, toggleConfirm] = useState(false);
	const [showPanelMove, togglePanelMove] = useState(false);

	const originalIndex = useRef(groupIndex);

	const resourceContext = useResources();
	const {
		moveGroupToPanel,
		initialSidebarPanels,
		subgroupAdded,
		updatedGroupTitle,
		updateTitle,
		deleteCollection,
		addCollection,
		isEditing,
		isReordering,
		reorderedPanel,
		setReorderedPanel,
	} = resourceContext;

	useEffect(() => {
		if (addingSubgroup && subgroupAdded) {
			setAddingSubgroup(false);
		}
	}, [subgroupAdded]);

	useEffect(() => {
		if (isUpdatingTitle && updatedGroupTitle) {
			toggleUpdatingTitle(false);
		}
	}, [updatedGroupTitle]);

	const saveTitle = (title) => {
		updateTitle({
			panelId,
			groupIndex,
			title,
		});
	};

	const handleDelete = () => {
		if (!isDeleting) {
			toggleDeleting(true);
			deleteCollection({
				panelId,
				groupIndex,
			});
		}
	};

	const saveNewSubgroup = (title) => {
		addCollection({ panelId, groupIndex, title });
	};

	const resourceCount = () => {
		return group.subgroups
			? group.subgroups.reduce((total, subgroup) => {
					const resources = subgroup.resources
						? subgroup.resources.filter((resource) => {
								return (
									!!resource.link ||
									(!!resource.pdf && !!resource.pdf.mediaItemUrl)
								);
						  })
						: [];
					return total + resources.length;
			  }, 0)
			: 0;
	};

	const handleMoveGroupToPanel = (newPanelId, panelTitle) => {
		const confirmed = window.confirm(
			`Are you sure you want to move ${group.title} into the ${panelTitle} panel?`
		);

		if (confirmed) {
			moveGroupToPanel(
				newPanelId,
				panelId,
				group.title,
				group.subgroups,
				originalIndex.current
			);

			let clonedPanel = cloneDeep(reorderedPanel);
			clonedPanel.groups.splice(groupIndex, 1);

			setReorderedPanel(clonedPanel);
		}
	};

	return (
		<>
			<Draggable draggableId={group.id} index={groupIndex}>
				{(provided) => (
					<StyledGroup
						ref={provided.innerRef}
						{...provided.draggableProps}
						isReordering={isReordering}
					>
						<div className='group-top'>
							<div className='drag-handle' {...provided.dragHandleProps}>
								<FontAwesomeIcon icon='grip-vertical' />
							</div>
							{isEditing && isUpdatingTitle ? (
								<TitleInput
									currentTitle={group.title}
									submit={saveTitle}
									cancel={toggleUpdatingTitle}
								/>
							) : (
								<>
									<h4>{group.title}</h4>
									<span className='count'>({resourceCount()})</span>
									<EditBtns
										show={isEditing}
										toggleEdit={toggleUpdatingTitle}
										toggleConfirm={toggleConfirm}
										isDeleting={isDeleting}
										grey
									/>
									{isReordering && (
										<a
											className='move-panel-link'
											href='#'
											onClick={() => togglePanelMove(!showPanelMove)}
										>
											Move to different panel
										</a>
									)}
								</>
							)}
						</div>
						<Droppable droppableId={group.id} type='subgroups'>
							{(provided) => (
								<div
									className='subgroup-section'
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{group.subgroups &&
										group.subgroups.map((subgroup, index) => (
											<Subgroup
												panelId={panelId}
												groupIndex={groupIndex}
												subgroupIndex={index}
												subgroup={subgroup}
												key={subgroup.id}
											/>
										))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
						{isEditing &&
							(addingSubgroup ? (
								<AddGroup
									level='Subgroup'
									handleShow={setAddingSubgroup}
									handleSubmit={saveNewSubgroup}
								/>
							) : (
								<Fab>
									<button
										className='add-collection-btn'
										onClick={() => setAddingSubgroup(true)}
									>
										<FontAwesomeIcon icon='plus' />
										<p>Add Subgroup</p>
									</button>
								</Fab>
							))}
					</StyledGroup>
				)}
			</Draggable>
			{showConfirm && (
				<Confirm
					password={group.title}
					close={() => toggleConfirm(false)}
					callback={handleDelete}
				>
					<p>
						Are you sure you want to delete this group and everything within it?
					</p>
				</Confirm>
			)}
			{showPanelMove && (
				<Modal toggleModal={togglePanelMove}>
					Which Panel would you like to move this group into?
					<PanelList>
						{initialSidebarPanels.length &&
							initialSidebarPanels.map((panel) => {
								{
									console.log(panel);
								}
								if (panel.resourcePanelId !== panelId) {
									return (
										<SquaredButton
											key={panel.id}
											onClick={() =>
												handleMoveGroupToPanel(
													panel.resourcePanelId,
													parse(panel.title)
												)
											}
										>
											{parse(panel.title)}
										</SquaredButton>
									);
								}
							})}
					</PanelList>
					<SquaredButton onClick={() => togglePanelMove(false)} cancel>
						<span>Cancel</span>
						<FontAwesomeIcon icon={["far", "window-close"]} />
					</SquaredButton>
				</Modal>
			)}
		</>
	);
};

const StyledGroup = styled.div`
	background: #fff;
	padding: 10px;
	min-width: 440px;
	max-width: ${(props) => (props.isReordering ? "100%" : "46%")};
	width: ${(props) => (props.isReordering ? "100%" : "auto")};
	flex: ${(props) => (props.isReordering ? "unset" : 1)};
	box-shadow: 0 5px 13px rgba(128, 128, 128, 0.25);
	margin: 0 2% 50px;
	align-self: flex-start;

	.group-top {
		margin-bottom: 10px;
		display: flex;
		align-items: center;

		h4 {
			margin: 0;
			padding: 4px 0;
		}

		span {
			margin-left: 15px;
			margin-right: 10px;
		}

		.drag-handle {
			padding: ${(props) => (props.isReordering ? "0 10px" : "0")};
			width: ${(props) => (props.isReordering ? "35px" : "0")};
			overflow: hidden;
		}

		.move-panel-link {
			margin: 0 15px 0 auto;
		}
	}

	@media (max-width: 650px) {
		min-width: 51%;
		max-width: unset;
		width: 100%;
		margin: 0 0 50px;
		box-shadow: 0 15px 13px -10px rgba(128, 128, 128, 0.25);
	}
`;

const PanelList = styled.div`
	border-bottom: 1px solid rgba(50, 50, 50, 0.1);
	padding-bottom: 10px;
	margin-bottom: 10px;

	button {
		margin: 10px 0;
	}
`;

export default Group;
