import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import Select from './Select';
import { GET_STATES } from '../graphql/queries';

const StateSelect = ({ handleSelect, className, selectedValue, name }) => {
  const { data, loading, error } = useQuery(GET_STATES);

  return (
    <Select
      handleSelect={handleSelect}
      selectedValue={selectedValue}
      name={name}
      title="State"
      className={className}
      loading={loading}
    >
      {error && <option>Error! No results returned</option>}
      {data &&
        data.states &&
        data.states.edges.map(state => (
          <option key={state.node.id} value={state.node.id}>
            {state.node.title}
          </option>
        ))}
      {!loading && !data.states && <option>No results!</option>}
    </Select>
  );
};

// Type Checking props
StateSelect.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default StateSelect;
