import gql from 'graphql-tag';

export {
  PROTECTED_QUERY,
  GET_CURRENT_USER,
  GET_USER_BY_ID,
  GET_USERS,
} from './user/userQueries';

export {
  GET_RESOURCES,
  GET_PANEL_RESOURCES,
  GET_PANELS,
} from './resources/resourceQueries';

export {
  GET_DISTRICTS,
  GET_STATES,
} from './districts/districtQueries'


// ----  GLOBAL QUERIES

export const GET_BANNER = gql`
  query getBanner {
    global {
      variables {
        announcementText
        bannerColor
      }
    }
  }
`;

export const GET_FIRST_NOTIFICATION = gql`
  query getFirstNotification{
    global{
      variables{
        notificationOneVisible
        notificationOneText
        notificationOneLink
        notificationOneLinkText
      }
    }
  }
`;

export const GET_SECOND_NOTIFICATION = gql`
  query getSecondNotification{
    global{
      variables{
        notificationTwoVisible
        notificationTwoText
        notificationTwoModalText
      }
    }
  }
`;