import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import logo from '../assets/img/amac-action-logo-white.png'
import { useAuth } from '../utilities/useAuth'
import UserNavBar from './UserNavBar'

const NavBar = () => {
	const auth = useAuth()

	return (
		<DoubleHeader>
			<UserNavBar />
			<Header>
				<div className='nav-container'>
					<Logo>
						<Link to='/'>
							<img src={logo} alt='AMAC Action Logo' />
						</Link>
					</Logo>
					<nav className='nav-links'>
						{auth.user && (
							<>
								<Link to='/'>Home</Link>
								{/* <Link to="/conference-registration">Conference</Link> */}
								{auth.user.roleIndex <= 2 && <Link to='/admin'>Admin</Link>}

								{auth.user.roleIndex <= 0 && (
									<>
										<div className='dropdown-group'>
											<button type='button'>Tools</button>
											<div className='dropdown-list'>
												<Link to='/representatives'>Representatives</Link>
												<Link to='/districts'>Districts</Link>
											</div>
										</div>
									</>
								)}

								<Link to='/resources'>Resources</Link>
							</>
						)}
					</nav>
				</div>
			</Header>
		</DoubleHeader>
	)
}

const DoubleHeader = styled.div`
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
`

const Header = styled.header`
	width: 100%;
	height: 65px;
	background-color: ${props => props.theme.darkBlue};
	font-family: ${props => props.theme.fontFamily};
	font-weight: 600;
	display: flex;
	align-items: center;

	.dropdown-group {
		.dropdown-list {
			position: absolute;
			height: 0;
			opacity: 0;
			overflow: hidden;
			border: 2px solid ${props => props.theme.mediumBlue};
			width: 180px;
			transition: all 0.25s ${props => props.theme.animation};
			background: #fff;
			z-index: 100;

			a {
				height: 49px;
				padding: 15px 10px;
				color: ${props => props.theme.darkText};
				font-weight: bold;
				background-color: #fff;

				&:hover {
					color: #fff;
				}
			}

			hr {
				z-index: 10;
				margin: auto;
				width: 60%;
				background-color: rgba(0, 0, 0, 0.1);
			}
		}

		&:hover .dropdown-list {
			height: 100px;
			opacity: 1;
		}
	}

	.nav-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		max-width: 1500px;
		margin: auto;
		padding: 0 2.5%;
	}

	.nav-links {
		display: flex;
		height: 100%;

		a,
		button {
			cursor: pointer;
			background-color: ${props => props.theme.darkBlue};
			font-family: inherit;
			font-weight: inherit;
			font-size: inherit;
			color: #fff;
			text-decoration: none;
			height: 100%;
			display: flex;
			align-items: center;
			text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
			padding: 0 15px;
			border: none;

			&:hover {
				background-color: ${props => props.theme.mediumBlue};
			}
		}
	}
`

const Logo = styled.div`
	font-size: 28px;
	color: white;
	height: 80%;

	img {
		height: 100%;
	}

	span {
		color: ${props => props.theme.lightRed};
	}
`

export default NavBar
