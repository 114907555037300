import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Error = ({ message }) => <ErrorMessage>{message}</ErrorMessage>;

const ErrorMessage = styled.div`
  width: 100%;
  background-color: #f7e1e2;
  border-left: 4px solid #f76569;
  padding: 15px;
  color: #f76569;
  margin: 10px auto;
`;

Error.propTypes = {
  message: PropTypes.string,
};

export default Error;
