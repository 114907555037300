import React, { useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parser from 'react-html-parser'

import Modal from "../global/Modal";

const BannerContent = ({ banner, toggleBanner, notificationText, modalText, linkText, notificationOneLinkText }) => {
  const [showModal, toggleModal] = useState(false);

  const animation = useSpring({
    height: banner ? "200px" : "0",
    padding: banner ? "25px 0" : "0 0",
  });


  return (
    <BannerContainer
      className="banner-section"
      style={animation}
      rgb="218,44,56"
    >
      <div className="banner">
        <div className="title">
          <div></div>
          <h4></h4>
          <FontAwesomeIcon
            icon={["far", "window-close"]}
            onClick={() => toggleBanner(!banner)} />
        </div>
        <div className="body">
          {parser(notificationText)}
          {linkText=='' ? 
          <button id="btn1" className="modal-button" onClick={() => toggleModal(true) }>
            More Information
          </button> : <button id="btn2" className="modal-button" onClick={() => window.location.href=linkText} > {parser(notificationOneLinkText)}</button> }
        </div>
      </div>
      {showModal && (
        <Modal toggleModal={toggleModal}>
          {parser(modalText)}
        </Modal>
      )}
    </BannerContainer>
  );
};

const BannerContainer = styled(animated.div)`
  // padding-top:15px;
  width: 48%;
  height:250px;
  margin: auto;
  overflow: hidden;
  @media(max-width:1300px){
    width:700px;
  }
  @media(max-width:710px){
    width:390px;
    // height: 300px;
  }

  .banner {
    margin: 0 2%;
    padding: 0px 0;
    max-height:250px;
    min-height:250px;
    max-width: 1500px;
  }


  #btn1{
    // left:390px;
    right:386px;
    top: 286px;
  }
  #btn2{
    // right:412px;
    left:420px;
    top: 286px;
  }

  .title {
    background-color: #06549b; 
    border: 2px solid #06549b;
    color: ${(props) => (props.light ? "inherit" : "#fff")};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 5px;

    h4 {
      margin: 0;
    }

    svg {
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .body {
    margin: 0;
    background-color: #06549b;
    text-align: center;
    padding: 0 15px 15px 15px;
    height:100px;
    // border: 2px solid #a3d4fd

    p {
      margin: 0;
      font-size: 16px;
      color:white;
    }

    .modal-button {
      background: ${(props) => props.theme.mediumBlue};
      font-weight: bold;
      color: #fff;
      margin-top: 10px;
      padding: 8px 32px;
      border: none;
      cursor: pointer;
    }
  }
`;

export default BannerContent;
