import gql from 'graphql-tag';

export const LOGIN_USER = gql`
  mutation loginUser(
    $username: String!
    $password: String!
    $clientMutationId: String!
  ) {
    login(
      input: {
        username: $username
        password: $password
        clientMutationId: $clientMutationId
      }
    ) {
      authToken
      refreshToken
      user {
        id
        userId
        firstName
        lastName
        username
        email
        customFields {
          disclaimerAgreement
        }
        roles {
          edges {
            node {
              name
            }
          }
        }
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($jwtRefreshToken: String!, $clientMutationId: String!) {
    refreshJwtAuthToken(
      input: {
        jwtRefreshToken: $jwtRefreshToken
        clientMutationId: $clientMutationId
      }
    ) {
      authToken
    }
  }
`;

export const REGISTER_USER = gql`
  mutation registerUser(
    $username: String!
    $password: String!
    $email: String!
  ) {
    registerUser(
      input: {
        username: $username
        email: $email
        password: $password
        clientMutationId: ""
      }
    ) {
      user {
        id
        username
      }
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail(
    $username: String!
    $clientMutationId: String!
  ) {
    sendPasswordResetEmail(
      input: { username: $username, clientMutationId: $clientMutationId }
    ) {
      user {
        username
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $clientMutationId: String!
    $key: String!
    $login: String!
    $password: String!
  ) {
    resetUserPassword(
      input: {
        clientMutationId: $clientMutationId
        key: $key
        login: $login
        password: $password
      }
    ) {
      user {
        username
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $clientMutationId: String!
    $id: ID!
    $password: String!
  ) {
    updateUser(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        password: $password
      }
    ) {
      user {
        username
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $clientMutationId: String!
    $username: String!
    $email: String!
    $roles: [String!]!
    $firstName: String
    $lastName: String
    $password: String
  ) {
    createUser(
      input: {
        clientMutationId: $clientMutationId
        username: $username
        email: $email
        firstName: $firstName
        lastName: $lastName
        roles: $roles
        password: $password
      }
    ) {
      user {
        id
        userId
        email
        username
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $clientMutationId: String!
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $roles: [String]
  ) {
    updateUser(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        roles: $roles
      }
    ) {
      user {
        id
        userId
        firstName
        lastName
        email
      }
    }
  }
`;

export const UPDATE_USER_ACF = gql`
  mutation updateUserACF(
    $clientMutationId: String!
    $userId: Int!
    $avatar: Int
    $districts: [Int]
    $disclaimerAgreement: Boolean
  ) {
    updateUserACF(
      input: {
        clientMutationId: $clientMutationId
        userId: $userId
        avatar: $avatar
        districts: $districts
        disclaimerAgreement: $disclaimerAgreement
      }
    ) {
      clientMutationId
      userId
      disclaimerAgreement
      avatar
      districts
    }
  }
`;