import styled from "styled-components";
import React, {useEffect,useState} from 'react';
import Loader from "../../global/Loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronDown, faChevronUp, faChevronCircleDown, faChevronCircleUp} from '@fortawesome/free-solid-svg-icons'

const Search = ({  currentPanel, searchTerm}) => {
  const [loading, setLoading] = useState(true)
  const [groupActive, setGroupActive] = useState(["", true])
  const [subgroupActive, setSubgroupActive] = useState(["", true])

  const handleGroupsOnClick = (group) => {
    setGroupActive([group.title, !groupActive[1]]);
    for(var i = 0; i < document.getElementsByName(group.title).length; i++){
      document.getElementsByName(group.title)[i].style.display === "flex" ? document.getElementsByName(group.title)[i].style.display = "none" : document.getElementsByName(group.title)[i].style.display = "flex"  
    }
  }
  const handleSubgroupsOnClick = ( group, subgroup) => {
    setSubgroupActive([group.title + subgroup.title, !subgroupActive[1]])
    var name = group.title.trim().toLowerCase().split(" ").join("") + subgroup.title.trim().toLowerCase().split(" ").join("")
    for(var i = 0; i < document.getElementsByName(name).length; i++){
      document.getElementsByName(name)[i].style.display === "flex" ? document.getElementsByName(name)[i].style.display = "none" : document.getElementsByName(name)[i].style.display = "flex" 
    }
  }

  const getGroups = (panel) => {
    const data = [];
    panel.customFields.groups && panel.customFields.groups.map((group) => {
      group.subgroups && group.subgroups.map((subgroup) =>{
        subgroup.resources && subgroup.resources.map((resource) =>{
          if(resource.title.toLowerCase().includes(searchTerm.trim().toLowerCase())){ 
            data.push(resource)           
          }
        })
      })
    })
    if(data.length === undefined || data.length === 0){
      return false
    }
    else{return true}
  }

  const getSubgroups = (group) => {
    const data = [];
    group.subgroups && group.subgroups.map((subgroup) =>{
      subgroup.resources && subgroup.resources.map((resource) =>{
        if(resource.title.toLowerCase().includes(searchTerm.trim().toLowerCase())){ 
          data.push(resource)
        }
      })
    })
    if(data.length === undefined || data.length === 0){
      return false
    }
    else{return true}
  }

  const getResources = (subgroup) => {
    const data = [];
      subgroup.resources && subgroup.resources.map((resource) =>{
        if(resource.title.toLowerCase().includes(searchTerm.trim().toLowerCase())){ 
          data.push(resource)
        }
      })
    if(data.length === undefined || data.length === 0){
      return false
    }
    else{return true}
  }
  
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    },3000) 
  }, [])
  
  while(loading){
    return <Loader />
  }

  return (
    <StyledSearch>
      {currentPanel.map((panel) => (
        <div className={getGroups(panel) ? "search-results" : "hide"} key={panel.id}>
            {getGroups(panel) ? <h1 className="panel-text">{panel.title.replace("&#8217;s", "").replace("#038;", "")}</h1> : <></>}
            {panel.customFields.groups.map((group, groupIndex) =>
              <div id={"group-" + groupIndex} className="groups"  key={"group_" + groupIndex}>  
                {getSubgroups(group) ? <div className="group-div"> <button id="group-btn"className="group-btn"  onClick={() => handleGroupsOnClick(group)} ><h2>{group.title}</h2> <h2>{groupActive[0] === group.title && !groupActive[1] ?  <FontAwesomeIcon icon={faChevronCircleUp} className='group-chevron-circle' /> : <FontAwesomeIcon icon={faChevronCircleDown} className='group-chevron-circle' /> }</h2></button> </div> : <></>}
                {group.subgroups != null ? group.subgroups.map((subgroup,subgroupIndex) =>    
                  <div id={"subgroup-" + subgroupIndex} className="subgroups" name={group.title} key={"subgroup_" + subgroupIndex}>
                    {getResources(subgroup) ? <button id="subgroup-btn" className="subgroup-btn" onClick={() => handleSubgroupsOnClick(group, subgroup)} ><h2>{subgroup.title} {subgroupActive[0] === group.title + subgroup.title && !subgroupActive[1] ? <FontAwesomeIcon icon={faChevronUp} className='chevron'/> : <FontAwesomeIcon icon={faChevronDown} className='chevron' /> }</h2> </button> : <></>}
                    {subgroup.resources && subgroup.resources.map((resource) =>   
                      <div id={subgroup.title} name={group.title.trim().toLowerCase().split(" ").join("") + subgroup.title.trim().toLowerCase().split(" ").join("")} className="resources" key={resource.id}>
                        {resource.title.toLowerCase().includes(searchTerm.trim().toLowerCase()) ? <a href={resource.link ? resource.link : resource.pdf.mediaItemUrl} className="resource" target="_blank">{resource.title} <FontAwesomeIcon icon='chevron-right' className='chevron-right' /></a>:<></>}
                      </div>
                    )}
                  </div>
                ) : console.log("there was a null value")}
              </div>
            )}
        </div>
      ))}




    </StyledSearch>
  )
}

const StyledSearch = styled.div`
  display:flex;
  flex-direction:column;
  flex-wrap: nowrap;
  width: 40%;
  .hide{
    display:none;
    visibility:hidden;
  }
  .search-results{
    display:flex;
    flex-direction: column;
    padding-bottom:25px;

    .panel-text{
      margin-bottom:10px;
      margin-left: 5px;
    }
    
    #group-btn{
      float:left;
      background-color:#fff;
      box-shadow: 3px 3px 8px #888888;
      border:none;
      margin: 5px;
      display:flex; 
      width:100%;
      justify-content: space-between;
      
      h2{
        text-align:left;
        padding-left:5px;
      }
    }

    #subgroup-btn{
      float:left;
      border-style: none;
      text-align: left;
      padding-left: 30px;
      background-color: #f5f8fd;
      display:flex; 
      width:fit-content;
    }
    button:focus{
      outline:0;
    }
    button:hover{
      cursor:pointer;
    }
    h2{
      color:black;
    }
    h3{
      color:black;
    }
    .resource{
      text-decoration:none;
      color: black;
      display:inline-block;
      padding: 0px 15px 23px 30px;
      
    }
    .group-div{
      display:flex;
      justify-content: space-between;
    }
    .groups{
      display:flex;
      flex-direction:column;
    }
    .hidden{
      display:none;
    }
    .subgroups{
      display:none;
      flex-direction:column;
      padding: 10px 0 10px 0;
    }
    .resources{
      display:none;
      flex-direction:column;
    }
    .chevron-right{
      color:#06549B;  
    }
    .chevron{
      color:#06549B;
    }
    .group-chevron-circle{
      color: #06549B;
      font-size: 24px;
      margin-right: 5px;
    }


  }
  
`
export default Search