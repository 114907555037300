import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Loader from '../../global/Loader';
import RepListItem from '../../global/RepListItem';
import createMarkup from '../../utilities/createMarkup';

const District = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_FULL_DISTRICT, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error up in here! ${error.message}`;

  const { title, customFields } = data.districtBy;
  const {
    abbreviation,
  } = data.districtBy.customFields.stateToDistricts[0].customFields;

  return (
    <DistrictPage>
      {data && (
        <div>
          <h1 dangerouslySetInnerHTML={createMarkup(title)} />
          <Map
            title={`${abbreviation}-${customFields.districtNumber}-map`}
            width="425"
            height="300"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src={`https://www.govtrack.us/congress/members/embed/mapframe?state=${abbreviation}&district=${customFields.districtNumber ||
              0}`}
          />
          <h4>Representatives</h4>
          <ul>
            {customFields.repToDistrict &&
              customFields.repToDistrict.map(rep => <RepListItem rep={rep} />)}
          </ul>
          {!customFields.repToDistrict && (
            <p>
              Sorry, representatives from this district have not yet been
              entered...
            </p>
          )}
        </div>
      )}
    </DistrictPage>
  );
};

const DistrictPage = styled.div`
  color: ${props => props.theme.darkText};
`;

const Map = styled.iframe`
  #footer a {
    display: none;
  }
`;

const GET_FULL_DISTRICT = gql`
  query fullDistrict($id: Int!) {
    districtBy(districtId: $id) {
      title
      customFields {
        districtNumber
        repToDistrict {
          ... on Representative {
            representativeId
            title
            customFields {
              firstName
              lastName
              party
              chamberOfCongress
              repImage {
                sourceUrl
              }
              chamberOfCongress
              party
            }
          }
        }
        stateToDistricts {
          ... on State {
            customFields {
              abbreviation
            }
          }
        }
      }
    }
  }
`;

District.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default District;
