import gql from 'graphql-tag'

/**
 * GraphQL protected query, an example of an authenticated query
 * If not authenticated it will return an error
 * If authenticated it will return the viewer's id and username
 */
export const PROTECTED_QUERY = gql`
  query ProtectedQuery {
    viewer {
      id
      username
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      customFields {
        disclaimerAgreement
      }
      id
      userId
      firstName
      lastName
      username
      email
      jwtAuthToken
      jwtRefreshToken
      roles {
        edges {
          node {
            name
          }
        }
      }
      districts {
        edges {
          node {
            districtId
            title
          }
        }
      }
      avatar(size: 200) {
        url
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      username
      email
      roles {
        edges {
          node {
            name
          }
        }
      }
      customFields {
        userToDistrict {
          ... on District {
            districtId
            title
          }
        }
      }
      avatar(size: 200) {
        url
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers {
    users(first: 5000) {
      edges {
        node {
          id
          firstName
          lastName
          username
          roles {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`;