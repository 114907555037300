import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../assets/img/amac-action-logo.png';
import AuthContainer from '../../styles/AuthContainer';
import { useAuth } from '../../utilities/useAuth';

import { UPDATE_USER_ACF, CHANGE_PASSWORD } from '../../graphql/mutations';
import { generateUniqueId } from '../../utilities/generateUniqueId';
import PasswordInput from '../../global/PasswordInput';
import theme from '../../styles/Theme';
import Button from '../../global/Button';

const Disclaimer = () => {
  const auth = useAuth();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [changePassword, { loading: passwordLoading }] = useMutation(
    CHANGE_PASSWORD,
    {
      onCompleted: res => {
        updateUserACF({
          variables: {
            clientMutationId: generateUniqueId(),
            userId: auth.user.userId,
            disclaimerAgreement: true,
          },
        });
      },
      onError: err => {
        console.log('error: ', err.message);
      },
    }
  );

  const [updateUserACF, { loading }] = useMutation(UPDATE_USER_ACF, {
    onCompleted: result => {
      if (result && result.updateUserACF.disclaimerAgreement) {
        auth.setUser({
          ...auth.user,
          customFields: {
            disclaimerAgreement: result.updateUserACF.disclaimerAgreement,
          },
        });
      }
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (password === confirmPassword) {
      changePassword({
        variables: {
          clientMutationId: generateUniqueId(),
          id: auth.user.id,
          password,
        },
      });
    }
  };

  return (
    <Container>
      <div className="top">
        <img src={logo} alt="AMAC Action Logo" />
        <h3>
          Member Area<span>Disclaimer Agreement</span>
        </h3>
      </div>
      <div className="body">
        <blockquote>
          As an AMAC Action Chapter Leader and/or Delegate, I understand and
          confirm that I MAY NOT share, nor personally use, any AMAC or AMAC
          Action member contact information, mailing list, electronic
          communication or any other such pertinent information with any third
          party, person or organization, and will hold and consider these
          materials to be confidential and privileged to be used only in my
          capacity as an AMAC Action Advocate Volunteer.
        </blockquote>
        <form method="post" onSubmit={handleSubmit}>
          <label htmlFor="agree" style={{ flexDirection: 'row' }}>
            <input id="agree" type="checkbox" required />I agree
          </label>
          <h3>Please reset your password</h3>
          <PasswordInput
            label="New Password"
            changePassword={setPassword}
            password={password}
          />
          <PasswordInput
            label="Confirm New Password"
            changePassword={setConfirmPassword}
            password={confirmPassword}
          />
          <Button color={theme.darkBlue} type="submit">
            {loading || passwordLoading ? (
              <FontAwesomeIcon icon="spinner" size="1x" spin />
            ) : (
              'Submit'
            )}
          </Button>
        </form>
      </div>
    </Container>
  );
};

const Container = styled(AuthContainer)`
  width: auto;
  max-width: 1000px;
  color: ${props => props.theme.lightText};
  font-weight: 500;
  border-radius: 50px;

  blockquote {
    border-left: 3px solid rgba(0, 0, 0, 0.2);
    padding-left: 25px;
  }

  form {
    margin: 25px 60px;
    display: flex;
    flex-direction: column;

    h3 {
      margin: 10px 0 0;
      color: ${props => props.theme.darkBlue};
    }

    label {
      margin: 10px;
      max-width: 400px;

      input {
        padding: 5px;
      }

      svg {
        margin-left: 10px;
      }
    }

    .pass {
      width: 96%;
    }

    button {
      max-width: 200px;
      margin: 10px;
    }
  }
`;

export default Disclaimer;
