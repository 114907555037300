import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import createMarkup from '../utilities/createMarkup';
import ListItem from '../styles/ListItem';

const DistrictListItem = ({ district }) => {
  const { districtId, title, customFields } = district;
  return (
    <ListItem key={districtId}>
      <Link to={`/districts/${districtId}`}>
        <div>
          <h3 dangerouslySetInnerHTML={createMarkup(title)} />
          <small>
            {customFields.repToDistrict
              ? customFields.repToDistrict.length
              : '0'}{' '}
            Representatives
          </small>
        </div>
        <div className="chevron">
          <FontAwesomeIcon icon="chevron-right" />
        </div>
      </Link>
    </ListItem>
  );
};

DistrictListItem.propTypes = {
  district: PropTypes.shape({
    districtId: PropTypes.number,
    title: PropTypes.string,
    customFields: PropTypes.shape({
      repToDistrict: PropTypes.array,
    }),
  }),
};

export default DistrictListItem;
