const theme = {
	lightBlue: '#f1f7fc',
	lightBlueTwo: '#62C3F4',
	mediumBlue: '#3581B8',
	darkBlue: '#06549B',
	lightRed: '#FF7A7E',
	mediumRed: '#DA2C38',
	mediumOrange: '#e65f20',
	mediumGreen: '#008000',
	accentMint: 'rgb(29, 211, 176)',
	darkText: '#393939',
	lightText: '#8b8c89',
	liBackground: '#fff',
	liBoxShadow: '0 3px 20px -10px rgba(0,0,0,0.3)',
	liBoxShadowHovered: '0 3px 20px 0 rgba(0,0,0,0.3)',
	fontFamily: "'Roboto', sans-serif",
	defaultBoxShadow: '0 7px 14px 0 rgba(60,66,87, .15), 0 3px 6px 0 rgba(0,0,0, .15)',
	modalOverlay: 'rgba(100, 100, 100, 0.5)',
	animation: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
}

export default theme
