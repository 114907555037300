import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';

import ForgotPassword from './ForgotPassword';
import { useAuth } from '../../utilities/useAuth';
import Error from '../../global/Error';
import AuthForm from '../../styles/AuthForm';
import theme from '../../styles/Theme';
import Button from '../../global/Button';

const Login = () => {
  const auth = useAuth();
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = data => {
    auth.login(data.username, data.password);
  };

  const errorMessages = new Map([
    [
      'invalid_username',
      'The username you entered was incorrect, please try again...',
    ],
    [
      'invalid_email',
      'The username you entered was incorrect, please try again...',
    ],
    [
      'incorrect_password',
      'The password you entered was incorrect, please try again...',
    ],
    ['default', 'An error has occurred, please try again...'],
  ]);

  if (isPasswordReset) return <ForgotPassword />;

  return (
    <>
      <AuthForm method="post" onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={auth.loginLoading} aria-busy={auth.loginLoading}>
          <label htmlFor="username">
            Username or Email
            <input
              id="username"
              name="username"
              type="text"
              ref={register({ required: true })}
            />
            {errors.username && (
              <p className="form-error">
                Please enter your email address or username.
              </p>
            )}
          </label>
          <label htmlFor="password">
            Password{' '}
            <span>
              {!showPassword ? (
                <FontAwesomeIcon
                  icon="eye"
                  onClick={() => setShowPassword(true)}
                  title="Show password"
                />
              ) : (
                <FontAwesomeIcon
                  icon="eye-slash"
                  onClick={() => setShowPassword(false)}
                  title="Hide password"
                />
              )}
            </span>
            <input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              ref={register({
                required: {
                  value: true,
                  message: 'Please enter your password.',
                },
                minLength: {
                  value: 6,
                  message: 'Passwords must be at least 6 characters.',
                },
              })}
            />
            {errors.password && (
              <p className="form-error">{errors.password.message}</p>
            )}
          </label>
          <Button color={theme.darkBlue} type="submit">
            {auth.loginLoading ? (
              <FontAwesomeIcon icon="spinner" size="1x" spin />
            ) : (
              'Submit'
            )}
          </Button>
          <button className="forgot-password" onClick={() => setIsPasswordReset(true)}>
            <small>Forgot Password?</small>
          </button>
        </fieldset>
      </AuthForm>
      {auth.loginError &&
        auth.loginError.graphQLErrors.map(errorKey => {
          const error =
            errorMessages.get(errorKey.message) || errorMessages.get('default');
          return <Error message={error} />;
        })}
    </>
  );
};

export default Login;
