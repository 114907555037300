import gql from "graphql-tag";

export const GET_RESOURCES = gql `
  query getResources {
    page(id: "resources", idType: URI) {
      customFields {
        panels {
          id
          title
          groups {
            id
            title
            subgroups {
              id
              title
              resources {
                id
                title
                pdf {
                  mediaItemUrl
                  databaseId
                }
                link
                dateupdated
                dateadded
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PANEL_RESOURCES = gql `
  query getPanelResources($id: ID!) {
    resourcePanel(id: $id) {
      id
      resourcePanelId
      title
      customFields {
        groups {
          id
          title
          subgroups {
            id
            title
            reverseOrder
            resources {
              id
              title
              pdf {
                mediaItemUrl
                databaseId
              }
              link
              dateupdated
              dateadded
            }
          }
        }
      }
    }
  }
`;

export const GET_PANELS = gql `
  {
    resourcePanels(first: 40) {
      nodes {
        id
        resourcePanelId
        title
        customFields {
          index
          groups {
            title
            subgroups {
              title
              resources {
                title
                id
                link
                pdf {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;