import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import styled from "styled-components";

import { useAuth } from "./utilities/useAuth";
import { GET_BANNER, GET_FIRST_NOTIFICATION, GET_SECOND_NOTIFICATION} from "./graphql/queries";

import Profile from "./pages/user/Profile";
import Admin from "./pages/admin/Admin";
import Reps from "./pages/representatives/Reps";
import Rep from "./pages/representative/Rep";
import District from "./pages/district/District";
import Districts from "./pages/districts/Districts";
import Bill from "./pages/legislation/Bill";
import Event from "./pages/event/Event";
import Events from "./pages/event/Events";
import "./App.css";
// import RegistrationPage from './pages/conference/RegistrationPage';
import PasswordReset from "./pages/auth/PasswordReset";
import FourOhFour from "./global/404";
import NavBar from "./global/NavBar";
import Auth from "./pages/auth/Auth";
import Loader from "./global/Loader";
import Disclaimer from "./pages/auth/Disclaimer";
import BannerContent from "./global/BannerContent";
import GlobalBanner from "./global/GlobalBanner";

import ResourcesPage from "./pages/resources/ResourcesPage";
import { useResources } from "./utilities/useResources.js";

const AppRouter = () => {
  const auth = useAuth();
  const location = useLocation();

  const [notification, toggleNotification] = useState(true);
  const [notificationTwo, toggleNotificationTwo] = useState(true)

  const { data: banner, refetch: refetchBanner } = useQuery(GET_BANNER);
  const { data: firstNotification, refetch: refetchFirstNotification} = useQuery(GET_FIRST_NOTIFICATION);
  const { data: secondNotification, refetch: refetchSecondNotification} = useQuery(GET_SECOND_NOTIFICATION);

  const resourceContext = useResources();
	let {
    brokenResource,
	} = resourceContext;


  // will only run once sicne auth.user is an object
  useEffect(() => {
    if (auth.user) {
      refetchBanner();
      refetchFirstNotification();
      refetchSecondNotification();
    }
  }, [auth.user, refetchBanner, refetchFirstNotification,refetchSecondNotification]);

  if (auth.protectedLoading || auth.userLoading || auth.user === null) {
    return (
      <MinContainer>
        <Loader min />
      </MinContainer>
    );
  }

  if (auth.user && !auth.user.customFields.disclaimerAgreement) {
    return (
      <MinContainer>
        <Disclaimer />
      </MinContainer>
    );
  }

  return (
    <>
      {auth.user ? (
        <>
          <NavBar />
          <Content className={location.pathname.slice(1)}>
            {brokenResource != "" && auth.user.roleIndex <= 2 &&  (
              <div className="broken-resource-alert">
                <h1>The following resource does not have a link or file:</h1>
                {brokenResource}
              </div>
            )}
            {banner &&
              banner.global &&
              banner.global.variables &&
              banner.global.variables.announcementText && (
                <GlobalBanner
                  bannerColor={banner.global.variables.bannerColor}
                  announcementText={banner.global.variables.announcementText}
                />
              )}

            <div className="notifications">
              {firstNotification &&
                firstNotification.global &&
                firstNotification.global.variables &&
                firstNotification.global.variables.notificationOneText &&
                firstNotification.global.variables.notificationOneVisible && (
                  <BannerContent
                    className="first-notification"
                    banner={notification}
                    toggleBanner={toggleNotification}
                    notificationText={firstNotification.global.variables.notificationOneText}
                    modalText=''
                    linkText= {firstNotification.global.variables.notificationOneLink}
                    notificationOneLinkText = {firstNotification.global.variables.notificationOneLinkText}
                  />
                )}
              {secondNotification &&
                secondNotification.global &&
                secondNotification.global.variables &&
                secondNotification.global.variables.notificationTwoText &&
                secondNotification.global.variables.notificationTwoVisible && (
                  <BannerContent
                    className="second-notification"
                    banner={notificationTwo}
                    toggleBanner={toggleNotificationTwo}
                    notificationText = {secondNotification.global.variables.notificationTwoText}
                    modalText = {secondNotification.global.variables.notificationTwoModalText}
                    linkText = ''
                    notificationOneLinkText = '' 
                  />
                )}
            </div>    




            <Switch>
              <Route exact path="/">
                <ResourcesPage />
              </Route>
              {auth.user.roleIndex <= 0 && (
                <Route exact path="/representatives">
                  <Reps />
                </Route>
              )}

              {auth.user.roleIndex <= 0 && (
                <Route exact path="/representatives/:id">
                  <Rep />
                </Route>
              )}

              {auth.user.roleIndex <= 0 && (
                <Route exact path="/districts">
                  <Districts />
                </Route>
              )}

              {auth.user.roleIndex <= 0 && (
                <Route exact path="/districts/:id">
                  <District />
                </Route>
              )}

              {auth.user.roleIndex <= 0 && (
                <Route exact path="/legislation/:id">
                  <Bill />
                </Route>
              )}

              {auth.user.roleIndex <= 0 && (
                <Route exact path="/events">
                  <Events />
                </Route>
              )}

              {auth.user.roleIndex <= 0 && (
                <Route exact path="/events/:id">
                  <Event />
                </Route>
              )}

              <Route exact path="/resources">
                <ResourcesPage />
              </Route>

              {/* <Route exact path="/conference-registration">
                <RegistrationPage />
              </Route> */}

              <Route exact path="/profile">
                <Profile />
              </Route>

              {auth.user.roleIndex <= 2 && (
                <Route exact path="/admin/:id?">
                  <Admin />
                </Route>
              )}

              <Route>
                <FourOhFour />
              </Route>
            </Switch>
          </Content>
        </>
      ) : (
        <MinContainer>
          <MinContent>
            <Switch>
              <Route
                exact
                path="/password-reset/:login/:key"
                component={PasswordReset}
              />
              <Route render={(props) => <Auth {...props} />} />
            </Switch>
          </MinContent>
        </MinContainer>
      )}
    </>
  );
};

const Content = styled.div`
  margin: 97px auto 0;
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.darkText};
  min-height: 100vh;

  &.conference-registration .banner-section {
    display: none;
  }

  &.new-resources {
    background: #f5f8fd;
  }
  .broken-resource-alert{
    text-align:center;
    background-color: #da2c38;
    color:white;
    padding:15px 0;
  }
  .broken-resource-alert h1{
    margin-top:0;
  }
  
  .notifications{
    display:flex;
    padding-top:15px;
  }
  @media(max-width:1300px){
    .notifications{
      flex-direction:column;
    } 
    .banner-section{
      padding-bottom:0px !important;
      padding-top: 0px !important;
      height: 175px !important;
    }
  }
    
`;

const MinContent = styled(Content)`
  min-height: 100vh;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const MinContainer = styled.div`
  background-color: ${(props) => props.theme.darkBlue};
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default AppRouter;
