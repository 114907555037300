import cloneDeep from 'lodash/cloneDeep'

export const reorderPanels = (result, panels) => {
	const { destination, source } = result
	const newPanels = cloneDeep(panels)

	const item = newPanels.splice(source.index, 1)
	newPanels.splice(destination.index, 0, item[0])

	return newPanels
}

export const reorderGroups = (result, panel) => {
	const { destination, source } = result
	const newPanel = cloneDeep(panel)

	const item = newPanel.groups.splice(source.index, 1)
	newPanel.groups.splice(destination.index, 0, item[0])
	return newPanel
}

export const reorderSubgroups = (result, panel) => {
	const { destination, source } = result
	const sourceIndexes = {}
	const destIndexes = {}
	const newPanel = cloneDeep(panel)

	for (var g = 0; g < panel.groups.length; g++) {
		if (newPanel.groups[g].id === source.droppableId) {
			sourceIndexes.group = g
		}
		if (newPanel.groups[g].id === destination.droppableId) {
			destIndexes.group = g
		}
	}

	const item = newPanel.groups[sourceIndexes.group].subgroups.splice(source.index, 1)

	if (newPanel.groups[destIndexes.group].subgroups) {
		newPanel.groups[destIndexes.group].subgroups.splice(destination.index, 0, item[0])
	} else {
		newPanel.groups[destIndexes.group].subgroups = item
	}

	return newPanel
}

export const reorderResources = (result, panel) => {
	const { destination, source } = result
	const sourceIndexes = {}
	const destIndexes = {}
	const newPanel = cloneDeep(panel)

	for (var g = 0; g < newPanel.groups.length; g++) {
		if (newPanel.groups[g].subgroups) {
			for (var s = 0; s < newPanel.groups[g].subgroups.length; s++) {
				if (newPanel.groups[g].subgroups[s].id === source.droppableId) {
					sourceIndexes.group = g
					sourceIndexes.subgroup = s
				}
				if (newPanel.groups[g].subgroups[s].id === destination.droppableId) {
					destIndexes.group = g
					destIndexes.subgroup = s
				}
			}
		}
	}

	const item = newPanel.groups[sourceIndexes.group].subgroups[sourceIndexes.subgroup].resources.splice(source.index, 1)

	if (newPanel.groups[destIndexes.group].subgroups[destIndexes.subgroup].resources) {
		newPanel.groups[destIndexes.group].subgroups[destIndexes.subgroup].resources.splice(destination.index, 0, item[0])
	} else {
		newPanel.groups[destIndexes.group].subgroups[destIndexes.subgroup].resources = item
	}

	return newPanel
}
