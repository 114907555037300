import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AddGroup = ({ level, handleSubmit, handleShow }) => {
	const [title, setTitle] = useState('')
	const [isSaving, toggleSaving] = useState(false)

	const save = e => {
		e.preventDefault()
		if (!isSaving) {
			toggleSaving(true)
			handleSubmit(title)
		}
	}

	return (
		<Form onSubmit={save}>
			<label htmlFor='group-title'>
				{level} Title
				<input
					type='text'
					id='group-title'
					value={title}
					onChange={e => setTitle(e.target.value)}
					disabled={isSaving}
				/>
			</label>
			<button type='submit' className='save-btn'>
				{isSaving ? <FontAwesomeIcon icon='spinner' size='xs' spin /> : <FontAwesomeIcon icon={['far', 'save']} />}
			</button>
			<button
				onClick={() => {
					if (!isSaving) {
						handleShow(false)
					}
				}}
				className='close-btn'
			>
				<FontAwesomeIcon icon={['far', 'window-close']} />
			</button>
		</Form>
	)
}

const Form = styled.form`
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	margin: 10px 15px;

	label {
		display: flex;
		flex-direction: column;
		color: ${props => props.theme.lightText};
		font-size: 18px;
		width: 100%;
		margin-right: 15px;

		input {
			padding: 5px;
			margin-top: 10px;
		}
	}

	button {
		cursor: pointer;
		font-size: 18px;
		border: none;
		background: none;
		padding: 4px 8px 2px;
		transition: all 0.25s ${props => props.theme.animation};

		&.save-btn {
			color: ${props => props.theme.mediumBlue};

			&:hover {
				color: #fff;
				background: ${props => props.theme.mediumBlue};
			}
		}

		&.close-btn {
			color: ${props => props.theme.mediumRed};

			&:hover {
				color: #fff;
				background: ${props => props.theme.mediumRed};
			}
		}
	}
`

export default AddGroup
