import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTransition, animated } from 'react-spring'

const EditBtns = ({ toggleEdit, toggleConfirm, isDeleting, grey, animation }) => {
	return (
		<>
			<AdminBtns grey={grey && grey.toString()} style={animation}>
				<button
					className='edit-btn'
					title='Edit Title'
					onClick={e => {
						e.stopPropagation()
						e.preventDefault()
						if (!isDeleting) {
							toggleEdit(true)
						}
					}}
				>
					<FontAwesomeIcon icon={['far', 'edit']} />
				</button>
				<button
					className='delete-btn'
					title='Delete'
					onClick={e => {
						e.stopPropagation()
						e.preventDefault()
						toggleConfirm(true)
					}}
				>
					{isDeleting ? (
						<FontAwesomeIcon icon='spinner' size='xs' spin />
					) : (
						<FontAwesomeIcon icon={['far', 'trash-alt']} />
					)}
				</button>
			</AdminBtns>
		</>
	)
}

const AdminBtns = styled(animated.div)`
	padding-right: 10px;
	min-width: 65px;

	button {
		cursor: pointer;
		background: none;
		border: none;
		padding: 7px;
		color: ${props => props.theme.lightText};
		transition: all 0.25s ${props => props.theme.animation};

		&.edit-btn {
			color: ${props => (props.grey ? 'inherit' : props.theme.mediumBlue)};

			&:hover {
				background: ${props => props.theme.mediumBlue};
				color: #fff;
			}
		}

		&.delete-btn {
			color: ${props => (props.grey ? 'inherit' : props.theme.mediumRed)};

			&:hover {
				background: ${props => props.theme.mediumRed};
				color: #fff;
			}
		}
	}
`

const EditBtnsWrapper = ({ show, toggleEdit, toggleConfirm, isDeleting, grey }) => {
	const transition = useTransition(show, null, {
		from: { transform: 'translateX(100%)', opacity: 0 },
		enter: { transform: 'translateX(0)', opacity: 1 },
		leave: { transform: 'translateX(100%)', opacity: 0 },
	})

	return (
		<>
			{transition.map(
				({ item, key, props: animation }) =>
					item && (
						<EditBtns
							key={key}
							animation={animation}
							toggleEdit={toggleEdit}
							toggleConfirm={toggleConfirm}
							isDeleting={isDeleting}
							grey={grey}
						/>
					)
			)}
		</>
	)
}

export default EditBtnsWrapper
