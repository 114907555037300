import styled from 'styled-components';

const AuthContainer = styled.div`
  margin: 50px auto;
  width: 100%;
  max-width: 500px;
  color: ${props => props.theme.lightText};
  padding: 50px 30px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: ${props => props.theme.defaultBoxShadow};

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    text-align: right;
    font-size: 1.3em;

    h3 {
      font-weight: lighter;
      text-transform: uppercase;
      margin: 0 0 15px;
    }

    span {
      font-weight: bold;
      display: block;
      color: ${props => props.theme.darkBlue};
    }
  }

  img {
    width: 175px;
  }
`;

export default AuthContainer;
