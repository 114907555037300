import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useAuth } from '../utilities/useAuth'

const UserNavBar = () => {
	const auth = useAuth()

	return (
		<Nav>
			<div className='inner'>
				<Link to='/profile'>Profile</Link>|
				<button type='button' onClick={auth.logout}>
					Logout
				</button>
			</div>
		</Nav>
	)
}

const Nav = styled.nav`
	background-color: #373636;
	color: #fff;

	.inner {
		width: 100%;
		max-width: 1500px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 0 auto;
		padding: 0 2.5%;

		a,
		button {
			background: none;
			border: none;
			color: #fff;
			text-decoration: none;
			cursor: pointer;
			font-size: 1em;
			padding: 7px 15px;
			transition: all 0.15s ${props => props.theme.animation};
			height: 100%;

			&:hover {
				opacity: 0.8;
			}
		}
	}
`

export default UserNavBar
