import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import ListItem from '../styles/ListItem';

const RepsListItem = ({ rep }) => {
  const {
    firstName,
    lastName,
    repImage,
    party,
    chamberOfCongress,
  } = rep.customFields;
  return (
    <RepLi>
      <Link to={`/representatives/${rep.representativeId}`}>
        <Photo img={repImage.sourceUrl} />
        <div className="name">
          <h3>
            {firstName} {lastName}
          </h3>
          <small>
            {chamberOfCongress} - {party}
          </small>
        </div>
        <div className="chevron">
          <FontAwesomeIcon icon="chevron-right" />
        </div>
      </Link>
    </RepLi>
  );
};

const Photo = styled.div`
  background-image: url(${props => props.img || '#aaa'});
  width: 75px;
  height: 75px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 50px;
`;

const RepLi = styled(ListItem)`
  .name {
    flex: 2;
  }
`;

RepsListItem.propTypes = {
  rep: PropTypes.shape({
    customFields: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      repImage: PropTypes.shape({
        sourceUrl: PropTypes.string,
      }),
      party: PropTypes.string,
      chamberOfCongress: PropTypes.string,
    }),
    representativeId: PropTypes.number,
  }),
};

export default RepsListItem;
