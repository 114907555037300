import styled from 'styled-components';

const UserForm = styled.form`
  padding: 20px 15px;
  max-width: 600px;

  .fieldset {
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    label {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 10px;
      margin-top: 10px;
      font-weight: bold;
      color: ${props => props.theme.lightText};
    }

    .half {
      width: 50%;
    }

    .full {
      width: 100%;
    }

    input,
    select {
      padding: 10px;
      margin-top: 10px;
    }
  }

  button {
    margin: 10px;
  }

  h3 {
    color: ${props => props.theme.mediumBlue};
    margin: 5px 0;
  }

  h4 {
    color: ${props => props.theme.lightText};
  }

  hr {
    margin: 30px 0 20px;
    border-color: ${props => props.theme.lightBlue};
  }

  .selected-districts {
    width: 100%;

    ul {
      max-width: 300px;
    }

    li {
      display: flex;
      padding: 10px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        border-bottom: none;
      }
    }

    p {
      margin: 0 15px;
    }

    .remove-btn {
      color: ${props => props.theme.mediumRed};
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.lightRed};
      }
    }
  }
`;

export default UserForm;
