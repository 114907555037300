import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from 'react-router-dom';

import logo from '../../assets/img/amac-action-logo.png';
import { useAuth } from '../../utilities/useAuth';
import Error from '../../global/Error';
import AuthContainer from '../../styles/AuthContainer';
import AuthForm from '../../styles/AuthForm';
import theme from '../../styles/Theme';
import Button from '../../global/Button';

const PasswordReset = ({ match }) => {
  const auth = useAuth();
  const [password, setPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    if (password === passwordMatch) {
      auth.resetPassword(match.params.key, match.params.login, password);
    } else {
      alert("The passwords you entered don't match, please try again...");
    }
  };

  const errorMessages = new Map([
    ['default', 'An error has occurred, please try again...'],
  ]);

  if (auth.resetSuccess) {
    alert(
      'Your password has been successfully reset, please use it to login...'
    );
    return <Redirect to="/" />;
  }

  return (
    <AuthContainer>
      <div className="top">
        <img src={logo} alt="AMAC Action Logo" />
        <h3>
          Member Area<span>Password Reset</span>
        </h3>
      </div>
      <AuthForm method="post" onSubmit={handleSubmit}>
        <fieldset disabled={auth.emailLoading} aria-busy={auth.emailLoading}>
          <label htmlFor="password">
            Password
            <input
              id="password"
              type="password"
              onChange={e => setPassword(e.target.value)}
              value={password}
              minLength="6"
            />
          </label>
          <label htmlFor="passwordMatch">
            Re-type Password
            <input
              id="passwordMatch"
              type="password"
              onChange={e => setPasswordMatch(e.target.value)}
              value={passwordMatch}
            />
          </label>
          <Button color={theme.darkBlue} type="submit">
            {auth.resetLoading ? (
              <FontAwesomeIcon icon="spinner" size="1x" spin />
            ) : (
              'Submit'
            )}
          </Button>
        </fieldset>
      </AuthForm>
      {auth.resetError && (
        <Error
          message={
            errorMessages.get(auth.resetError.message) ||
            errorMessages.get('default')
          }
        />
      )}
    </AuthContainer>
  );
};

export default PasswordReset;
