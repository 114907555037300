import gql from 'graphql-tag'

export const CONFERENCE_REGISTRATION_SUBMIT = gql`
  mutation conferenceRegistrationSubmit(
    $clientMutationId: String!
    $form: ConfForm
    ) {
    conferenceRegistrationSubmit(
      input: {
        clientMutationId: $clientMutationId
        form: $form
    }) {
      clientMutationId
    }
  }
`;