import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';

import DistrictListItem from '../../global/DistrictListItem';
import createMarkup from '../../utilities/createMarkup';

const EventDetails = ({ content, allDay, start, end, districts }) => {
  const startDate = moment(start).format('MMMM D, YYYY');
  const startTime = moment(start).format('hh:mm a');
  const endDate = moment(end).format('MMMM D, YYYY');
  const endTime = moment(end).format('hh:mm a');

  return (
    <Card>
      <div className="detail">
        <p>
          <b>Date: </b>
        </p>
        <p>
          {startDate === endDate ? startDate : `${startDate} to ${endDate}`}
        </p>
      </div>
      <div className="detail">
        <p>
          <b>Time: </b>
        </p>
        <p>
          {allDay === 'yes'
            ? '*This is an all day event'
            : `${startTime} - ${endTime}`}
        </p>
      </div>
      {content && (
        <div className="detail">
          <p>
            <b>Description: </b>
          </p>
          <p dangerouslySetInnerHTML={createMarkup(content)} />
        </div>
      )}
      <div className="districts">
        <p>{districts.length > 1 ? <b>District(s)</b> : <b>District</b>}</p>
        {districts.map(district => (
          <DistrictListItem key={district.districtId} district={district} />
        ))}
      </div>
    </Card>
  );
};

const Card = styled.div`
  background-color: #fff;
  box-shadow: ${props => props.theme.liBoxShadow};
  padding: 15px 25px;
`;

EventDetails.propTypes = {
  content: PropTypes.string,
  allDay: PropTypes.bool,
  start: PropTypes.string,
  end: PropTypes.string,
  districts: PropTypes.array,
};

export default EventDetails;
