const withinNumDays = (date, numDays) => {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);

    const updated = new Date(year, month - 1, day);
    const today = new Date();
    const diffTime = Math.abs(today - updated);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= numDays;
}

export default withinNumDays;