import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from '../../utilities/useAuth';
import Error from '../../global/Error';
import Success from '../../global/Success';
import AuthForm from '../../styles/AuthForm';
import Button from '../../global/Button';
import theme from '../../styles/Theme';

const ForgotPassword = props => {
  const auth = useAuth();
  const [username, setUsername] = useState(props.username || '');

  const handleSubmit = e => {
    e.preventDefault();
    auth.sendPasswordResetEmail(username);
  };

  const errorMessages = new Map([
    [
      'GraphQL error: Invalid username.',
      'The username you entered was incorrect, please try again...',
    ],
    ['default', 'An error has occurred, please try again...'],
  ]);

  return (
    <>
      <AuthForm method="post" onSubmit={handleSubmit}>
        <fieldset disabled={auth.emailLoading} aria-busy={auth.emailLoading}>
          <label htmlFor="username">
            Username or Email
            <input
              id="username"
              type="text"
              onChange={e => setUsername(e.target.value)}
              value={username}
            />
          </label>
          <Button color={theme.darkBlue} type="submit">
            {auth.emailLoading ? (
              <FontAwesomeIcon icon="spinner" size="1x" spin />
            ) : (
              'Send Password Reset Email'
            )}
          </Button>
        </fieldset>
      </AuthForm>
      {auth.emailError && (
        <Error
          message={
            errorMessages.get(auth.emailError.message) ||
            errorMessages.get('default')
          }
        />
      )}
      {auth.emailSuccess && (
        <Success message="A password reset email has been sent to the email address on file." />
      )}
    </>
  );
};

export default ForgotPassword;
