import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import styled from 'styled-components';

import RepListItem from '../../global/RepListItem';
import Loader from '../../global/Loader';
import StateSelect from '../../global/StateSelect';

const Reps = () => {
  const [searchedState, setSearchedState] = useState(null);
  return (
    <RepsPage>
      <h1>Representatives</h1>
      <StateSelect
        handleSelect={setSearchedState}
        selectedValue={searchedState}
        title="rep-state-select"
      />

      {searchedState && (
        <Query query={GET_REPS} variables={{ id: searchedState }}>
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error) return `Error! ${error.message}`;

            const { stateToRepresentatives } = data.stateBy.customFields;

            return (
              <RepList>
                {stateToRepresentatives &&
                  stateToRepresentatives.map(rep => (
                    <RepListItem key={rep.representativeId} rep={rep} />
                  ))}

                {!stateToRepresentatives &&
                  'Sorry, no Representatives in this state have been uploaded yet...'}
              </RepList>
            );
          }}
        </Query>
      )}
    </RepsPage>
  );
};

const RepsPage = styled.div`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.darkText};
`;

const RepList = styled.ul`
  max-width: 750px;
`;

const GET_REPS = gql`
  query getReps($id: ID!) {
    stateBy(id: $id) {
      customFields {
        stateToRepresentatives {
          ... on Representative {
            representativeId
            customFields {
              firstName
              lastName
              party
              chamberOfCongress
              repImage {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

export default Reps;
