/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'

import { useResources } from '../../../utilities/useResources'
import EditResource from './EditResource'
import withinNumDays from '../../../utilities/withinNumDays'
import EditBtns from './EditBtns'
import Confirm from '../../../global/Confirm'

const icon = new Map([
	['link', 'external-link-alt'],
	['pdf', 'file-pdf'],
	['pptx', 'file-powerpoint'],
	['ppt', 'file-powerpoint'],
	['png', 'file-image'],
	['jpg', 'file-image'],
	['jpeg', 'file-image'],
	['xlsx', 'file-excel'],
	['empty', 'file'],
])

const Resource = ({ panelId, groupIndex, subgroupIndex, resourceIndex, resource, type }) => {
	const [isNew, setIsNew] = useState(false)
	const [isUpdated, setIsUpdated] = useState(false)

	const [isDeleting, setIsDeleting] = useState(false)
	const [showConfirm, toggleConfirm] = useState(false)
	const [editingResource, setEditingResource] = useState(false)

	useEffect(() => {
		if (resource && resource.dateupdated) {
			const updated = withinNumDays(resource.dateupdated, 30)
			setIsUpdated(updated)
		}
		if (resource && resource.dateadded) {
			const added = withinNumDays(resource.dateadded, 30)
			setIsNew(added)
		}
	}, [resource.dateupdated, resource.dateadded, resource])

	const resourceContext = useResources()
	let { updated, deleting, deleteCollection, isEditing, isReordering } = resourceContext

	useEffect(() => {
		if (updated) {
			setEditingResource(false)
		}
	}, [JSON.stringify(updated)])

	useEffect(() => {
		if (isDeleting && !deleting) {
			setIsDeleting(false)
		}
	}, [isDeleting, deleting])

	const handleDelete = () => {
		setIsDeleting(true)

		const databaseId = resource.pdf && resource.pdf.databaseId ? resource.pdf.databaseId : null
		deleteCollection({
			panelId,
			groupIndex,
			subgroupIndex,
			resourceIndex,
			databaseId,
		})
	}

	let resourceLink

	if (resource.link) {
		resourceLink = resource.link
	} else if (resource.pdf) {
		resourceLink = resource.pdf.mediaItemUrl
	} else {
		resourceLink = '#'
	}

	return (
		<>
			<Draggable draggableId={resource.id} index={resourceIndex}>
				{provided => (
					<StyledResource
						ref={provided.innerRef}
						{...provided.draggableProps}
						isReordering={isReordering}
						isEditing={isEditing}
					>
						<a href={resourceLink} target='_blank' rel='noreferrer'>
							<div className='resource-left'>
								<div className='drag-handle' {...provided.dragHandleProps}>
									<FontAwesomeIcon icon='grip-vertical' />
								</div>
								<FontAwesomeIcon className={`resource-type ${type}`} icon={icon.get(type)} />
								<span>{resource.title}</span>
							</div>
							<div className='resource-right'>
								<div className='badges'>
									{isNew && <FontAwesomeIcon className='badge new-badge' icon='asterisk' />}
									{isUpdated && <FontAwesomeIcon className='badge updated-badge' icon='flag' />}
								</div>

								<EditBtns
									show={isEditing}
									toggleEdit={setEditingResource}
									isDeleting={isDeleting}
									toggleConfirm={toggleConfirm}
								/>

								{!isEditing && <FontAwesomeIcon className='chevron' icon='chevron-right' />}
							</div>
						</a>
					</StyledResource>
				)}
			</Draggable>

			{editingResource && (
				<EditResource
					resource={{
						id: resource.id,
						title: resource.title,
						link: resource.link,
						dateupdated: resource.dateupdated,
						dateadded: resource.dateadded,
						previewSrc: resource.pdf ? resource.pdf.mediaItemUrl : null,
						fileId: resource.pdf ? resource.pdf.databaseId : null,
						type,
						panelId,
						groupIndex,
						subgroupIndex,
						resourceIndex,
					}}
					toggleOpen={setEditingResource}
				/>
			)}

			{showConfirm && (
				<Confirm close={() => toggleConfirm(false)} callback={handleDelete}>
					<p>Are you sure you want to delete this resource?</p>
				</Confirm>
			)}
		</>
	)
}

const StyledResource = styled.li`
	background: #fff;
	box-shadow: ${props => props.theme.liBoxShadow};
	z-index: 3;

	a {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: ${props => props.theme.darkText};
		text-decoration: none;

		.resource-left {
			display: flex;
			align-items: center;
			flex: 1;
			padding: 15px;

			.drag-handle {
				padding: ${props => (props.isReordering ? '0 10px' : '0')};
				width: ${props => (props.isReordering ? '35px' : '0')};
				overflow: hidden;
			}
		}

		span {
			flex: 1;
			font-size: 13.5px;
		}

		.resource-type {
			margin-right: 25px;
			font-size: 28px;
		}

		.link {
			color: ${props => props.theme.lightText};
		}

		.pdf {
			color: ${props => props.theme.mediumRed};
		}

		.pptx,
		.ppt {
			color: ${props => props.theme.mediumOrange};
		}

		.png,
		.jpg,
		.jpeg {
			color: ${props => props.theme.accentMint};
		}

		.xlsx {
			color: ${props => props.theme.mediumGreen};
		}

		.resource-right {
			display: flex;
			align-items: center;
			align-self: stretch;
			position: relative;
			overflow: hidden;
			color: ${props => props.theme.lightText};

			.badges {
				height: 100%;
				background: transparent;
				display: flex;
				align-items: center;
				z-index: 2;
			}

			svg {
				margin: 0 10px;
				transition: transform 0.4s ${props => props.theme.animation};
			}

			.new-badge {
				color: ${props => props.theme.mediumRed};
			}

			.updated-badge {
				color: ${props => props.theme.mediumBlue};
			}

			.chevron {
				margin-right: 15px;
			}
		}
	}

	&:hover {
		background: ${props => props.theme.lightBlue};

		.chevron {
			transform: translateX(3px);
		}
	}
`

export default Resource
