import styled from 'styled-components';

const AuthForm = styled.form`
  width: 100%;

  fieldset {
    border: none;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
  }

  label {
    font-weight: bold;
    color: ${props => props.theme.lightText};

    .form-error {
      margin: -15px 0 15px;
      color: ${props => props.theme.mediumRed};
      font-weight: normal;
    }
  }

  input,
  select {
    width: 96%;
    margin: 10px auto 25px;
    font-size: 20px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .forgot-password {
    margin-top: 15px;
    background: none;
    border: none;
    text-decoration: underline;
    color: ${props => props.theme.mediumBlue};
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.darkBlue};
    }
  }

  sup {
    font-size: 0.6em;
  }

  span {
    cursor: pointer;
  }
`;

export default AuthForm;
