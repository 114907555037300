import gql from 'graphql-tag'

export const UPDATE_RESOURCE_STRUCTURE = gql`
  mutation updateResourceStructure(
    $clientMutationId: String!
    $resourceStructure: [Panel]
  ) {
    updateResourceStructure(
      input: {
        clientMutationId: $clientMutationId
        resourceStructure: $resourceStructure
      }
    ) {
      clientMutationId
    }
  }
`;

export const ADD_RESOURCE = gql`
  mutation addResource(
    $clientMutationId: String!
    $panelId: Int!
    $groupIndex: Int!
    $subgroupIndex: Int!
    $resource: Resource
  ) {
    addResource(
      input: {
        clientMutationId: $clientMutationId
        panelId: $panelId
        groupIndex: $groupIndex
        subgroupIndex: $subgroupIndex
        resource: $resource
      }
    ) {
      clientMutationId
      panelId
      groupIndex
      subgroupIndex
      id
      title
      link
      databaseId
      mediaItemUrl
      dateadded
      dateupdated
    }
  }
`;



export const UPDATE_RESOURCE = gql`
  mutation updateResource(
    $clientMutationId: String!
    $panelId: Int!
    $groupIndex: Int!
    $subgroupIndex: Int!
    $resourceIndex: Int!
    $resource: Resource
  ) {
    updateResource(
      input: {
        clientMutationId: $clientMutationId
        panelId: $panelId
        groupIndex: $groupIndex
        subgroupIndex: $subgroupIndex
        resourceIndex: $resourceIndex
        resource: $resource
      }
    ) {
      clientMutationId
      panelId
      groupIndex
      subgroupIndex
      resourceIndex
      id
      title
      link
      databaseId
      mediaItemUrl
      dateadded
      dateupdated
    }
  }
`;

export const DELETE_RESOURCE = gql`
  mutation deleteResource(
    $clientMutationId: String!
    $panelId: Int!
    $groupIndex: Int!
    $subgroupIndex: Int!
    $resourceIndex: Int!
    $databaseId: Int
  ) {
    deleteResource(
      input: {
        clientMutationId: $clientMutationId
        panelId: $panelId
        groupIndex: $groupIndex
        subgroupIndex: $subgroupIndex
        resourceIndex: $resourceIndex
        databaseId: $databaseId
      }
    ) {
      clientMutationId
      panelId
      groupIndex
      subgroupIndex
      resourceIndex
    }
  }
`;