import React, { useState } from 'react';
import { Query } from '@apollo/react-components';
import styled from 'styled-components';

import Loader from '../../global/Loader';
import StateSelect from '../../global/StateSelect';
import DistrictListItem from '../../global/DistrictListItem';
import { GET_DISTRICTS } from '../../graphql/queries';

const Districts = () => {
  const [searchedState, setSearchedState] = useState(null);

  return (
    <DistrictsPage>
      <h1>Districts</h1>
      <StateSelect
        handleSelect={setSearchedState}
        selectedValue={searchedState}
        title="district-state-select"
      />
      {searchedState && (
        <Query query={GET_DISTRICTS} variables={{ id: searchedState }}>
          {({ data, loading, error }) => {
            if (loading) return <Loader />;
            if (error) return `Error! ${error.message}`;

            const { stateToDistricts } = data.stateBy.customFields;

            // Sort districts by number
            stateToDistricts.sort((a, b) => {
              const aNum = a.title.split(' ')[2];
              const bNum = b.title.split(' ')[2];
              return aNum - bNum;
            });

            return (
              <DistrictList>
                {stateToDistricts &&
                  stateToDistricts.map(district => (
                    <DistrictListItem district={district} />
                  ))}
              </DistrictList>
            );
          }}
        </Query>
      )}
    </DistrictsPage>
  );
};

const DistrictsPage = styled.div`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.darkText};
`;

const DistrictList = styled.ul`
  list-style: none;
`;

export default Districts;
