/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, createContext, useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import cloneDeep from "lodash/cloneDeep";

import { useAuth } from "./useAuth";
import EditResource from "../pages/resources/components/EditResource";
import { GET_PANELS, GET_PANEL_RESOURCES } from "../graphql/queries";
import {
	ADD_PANEL,
	UPDATE_PANEL_TITLE,
	DELETE_PANEL,
	ADD_GROUP,
	UPDATE_GROUP_TITLE,
	DELETE_GROUP,
	ADD_SUBGROUP,
	UPDATE_SUBGROUP_TITLE,
	DELETE_SUBGROUP,
	ADD_RESOURCE,
	UPDATE_RESOURCE,
	DELETE_RESOURCE,
	REORDER_PANELS,
	REORDER_PANEL_CONTENTS,
	MOVE_GROUP_TO_PANEL,
} from "../graphql/mutations";
import { generateUniqueId } from "./generateUniqueId";

const ResourceContext = createContext();

export const ResourcesProvider = ({ children }) => {
	const resources = useProvideResources();
	// console.log(resources)
	return (
		<ResourceContext.Provider value={resources}>
			{" "}
			{children}{" "}
		</ResourceContext.Provider>
	);
};

export const useResources = () => useContext(ResourceContext);

const useProvideResources = () => {

	const auth = useAuth();

	const [showLoader, toggleLoader] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isReordering, setIsReordering] = useState(false);
	const [isReorderingPanels, setIsReorderingPanels] = useState(false);
	const [brokenResource, setBrokenResource] = useState("");

	const toggleReordering = () => {
		setIsReordering(!isReordering);
	};

	const toggleReorderingPanels = () => {
		setIsReorderingPanels(!isReorderingPanels);
	};
	const isBroken = (resourcePanel, group, groupIndex, subgroup, subgroupIndex, resource, resourceIndex) =>{

		//add index for group, subgroup and resource in the .maps below (just like in the search.js). and then use resources.updateResource() from EditResource.js
			updateResource(
				resourcePanel.panelId,
				groupIndex,
				subgroupIndex,
				resourceIndex,
				{
					id: resource.id,
					link: "https://dashboard.amacaction.org",
					pdf: 0,
					title: resource.title,
					// dateupdated: removeDateUpdated ? '19890420' : getTodayFormatted(),
					// dateadded: removeDateAdded ? '19890420' : resource.dateadded,
				}
			)
			setBrokenResource(resourcePanel.title + " > " + group.title +" > " + subgroup.title+" > " + resource.title);
			return 'https://dashboard.amacaction.org';
		
	}

	const [initialSidebarPanels, setInitialSidebarPanels] = useState([]);
	const [reorderedSidebarPanels, setReorderedSidebarPanels] = useState([]);
	const [currentPanelId, setCurrentPanelId] = useState("");
	const [currentPanel, setCurrentPanel] = useState({});
	const [reorderedPanel, setReorderedPanel] = useState({});

	const [getCurrentPanel, { loading: currentPanelLoading }] = useLazyQuery(
		GET_PANEL_RESOURCES,
		{
			fetchPolicy: auth.user && auth.user.roleIndex <= 2 ? "network-only" : "",
			onCompleted: (res) => {
				if (res && res.resourcePanel) {
					const { id, resourcePanelId, title } = res.resourcePanel;
					const defaultedPanelGroups = !res.resourcePanel.customFields.groups
						? []
						: res.resourcePanel.customFields.groups.map((group,groupIndex) => {
								return {
									...group,
									subgroups: !group.subgroups
										? []
										: group.subgroups.map((subgroup,subgroupIndex) => {
												return {
													...subgroup,
													resources: !subgroup.resources
														? []
														: subgroup.resources.map((resource,resourceIndex) => {
																return {
																	...resource,
																	link: (resource.pdf === null && resource.link === null) || (resource.pdf === null && resource.link === "") ? isBroken(res.resourcePanel, group, groupIndex, subgroup, subgroupIndex, resource, resourceIndex)  : resource.link,
																	pdf: resource.pdf == null ? null : resource.pdf,
																};
														  }),
												};
										  }),
								};
						  });

					setCurrentPanel({
						id,
						resourcePanelId,
						title,
						groups: defaultedPanelGroups,
					});
				}
			},
		}
	);

	const [getSidebarPanels, { loading: sidebarPanelsLoading }] = useLazyQuery(
		GET_PANELS,
		{
			fetchPolicy: auth.user && auth.user.roleIndex <= 2 ? "network-only" : "",
			onCompleted: (res) => {
				if (res && res.resourcePanels) {
					const sortedPanels = res.resourcePanels.nodes.sort((a, b) =>
						a.customFields.index > b.customFields.index ? 1 : -1
					);
					setInitialSidebarPanels(sortedPanels);
					if (!currentPanelId) {
						setCurrentPanelId(sortedPanels[0].id);
					}
				}
			},
		}
	);

	useEffect(() => {
		if (currentPanelId) {
			getCurrentPanel({
				variables: {
					id: currentPanelId,
				},
			});
		}
	}, [currentPanelId]);

	// only get resources if there is a user
	useEffect(() => {
		if (auth.user) {
			getSidebarPanels();
		}
	}, [auth.user]);

	useEffect(() => {
		if (isReordering || isReorderingPanels || isEditing) {
			getCurrentPanel({
				variables: {
					id: currentPanelId,
				},
			});
			getSidebarPanels();
		}
	}, [isReordering, isReorderingPanels, isEditing]);

	//------- NEW MUTATIONS ------//

	// Add, Update, Delete mutation declarations

	const [
		addPanelMutation,
		{ loading: addingPanel, data: panelAdded },
	] = useMutation(ADD_PANEL, {
		onCompleted: (res) => {
			if (res.addPanel && res.addPanel.title) {
				alert(res.addPanel.title + " added successfully!");
			}
			getSidebarPanels();
		},
	});

	const [updatePanelTitleMutation, { data: updatedPanelTitle }] = useMutation(
		UPDATE_PANEL_TITLE,
		{
			onCompleted: (res) => {
				if (res && res.updatePanelTitle) {
					getSidebarPanels();
				}
			},
			onError: (err) => {
				console.log("update panel title error: ", err.message);
			},
		}
	);

	const [deletePanelMutation] = useMutation(DELETE_PANEL, {
		onCompleted: (res) => {
			getSidebarPanels();
		},
		onError: (err) => {
			console.log("error deleting panel: ", err.message);
		},
	});

	const [
		addGroupMutation,
		{ loading: addingGroup, data: groupAdded },
	] = useMutation(ADD_GROUP, {
		onCompleted: (res) => {
			if (res && res.addGroup) {
				const { id, title } = res.addGroup;

				const newGroup = {
					id,
					title,
					subgroups: [],
				};

				const clonedPanel = cloneDeep(currentPanel);

				if (clonedPanel.groups) {
					clonedPanel.groups.push(newGroup);
				} else {
					clonedPanel.groups = [newGroup];
				}

				setCurrentPanel(clonedPanel);
			}
		},
		onError: (err) => {
			console.log(err);
		},
	});

	const [updateGroupTitleMutation, { data: updatedGroupTitle }] = useMutation(
		UPDATE_GROUP_TITLE,
		{
			onCompleted: (res) => {
				if (res && res.updateGroupTitle) {
					const { groupIndex, title } = res.updateGroupTitle;

					const clonedPanel = cloneDeep(currentPanel);

					clonedPanel.groups[groupIndex].title = title;

					setCurrentPanel(clonedPanel);
				}
			},
			onError: (err) => {
				console.log("update group title error: ", err.message);
			},
		}
	);

	const [deleteGroupMutation] = useMutation(DELETE_GROUP, {
		onCompleted: (res) => {
			if (res && res.deleteGroup) {
				const { groupIndex } = res.deleteGroup;

				const clonedPanel = cloneDeep(currentPanel);

				clonedPanel.groups.splice(groupIndex, 1);

				setCurrentPanel(clonedPanel);
			}
		},
		onError: (err) => {
			console.log("error deleting subgroup: ", err.message);
		},
	});

	const [
		addSubgroupMutation,
		{ loading: addingSubgroup, data: subgroupAdded },
	] = useMutation(ADD_SUBGROUP, {
		onCompleted: (res) => {
			if (res && res.addSubgroup) {
				const { groupIndex, id, title } = res.addSubgroup;

				const newSubgroup = {
					id,
					title,
					resources: [],
				};

				const clonedPanel = cloneDeep(currentPanel);

				if (!clonedPanel.groups[groupIndex].subgroups) {
					clonedPanel.groups[groupIndex].subgroups = [];
				}

				clonedPanel.groups[groupIndex].subgroups.push(newSubgroup);

				setCurrentPanel(clonedPanel);
			}
		},
	});

	const [
		updateSubgroupTitleMutation,
		{ data: updatedSubgroupTitle },
	] = useMutation(UPDATE_SUBGROUP_TITLE, {
		onCompleted: (res) => {
			if (res && res.updateSubgroupTitle) {
				const { groupIndex, subgroupIndex, title } = res.updateSubgroupTitle;

				const clonedPanel = cloneDeep(currentPanel);

				clonedPanel.groups[groupIndex].subgroups[subgroupIndex].title = title;

				setCurrentPanel(clonedPanel);
			}
		},
		onError: (err) => {
			console.log("update subgroup title error: ", err.message);
		},
	});

	const [deleteSubgroupMutation] = useMutation(DELETE_SUBGROUP, {
		onCompleted: (res) => {
			if (res && res.deleteSubgroup) {
				const { groupIndex, subgroupIndex } = res.deleteSubgroup;

				const clonedPanel = cloneDeep(currentPanel);

				clonedPanel.groups[groupIndex].subgroups.splice(subgroupIndex, 1);

				setCurrentPanel(clonedPanel);
			}
		},
		onError: (err) => {
			console.log("error deleting subgroup: ", err.message);
		},
	});

	let [addResourceMutation, { loading: adding, data: added }] = useMutation(
		ADD_RESOURCE,
		{
			onCompleted: (res) => {
				if (res && res.addResource) {
					const {
						groupIndex,
						subgroupIndex,
						id,
						title,
						link,
						databaseId,
						mediaItemUrl,
						dateupdated,
						dateadded,
					} = res.addResource;
					const newResource = {
						id,
						title,
						link,
						pdf: {
							databaseId,
							mediaItemUrl,
						},
						dateupdated,
						dateadded,
					};

					const clonedPanel = cloneDeep(currentPanel);


					if(clonedPanel.groups[groupIndex].subgroups[subgroupIndex].resources[0].dateadded > clonedPanel.groups[groupIndex].subgroups[subgroupIndex].resources[clonedPanel.groups[groupIndex].subgroups[subgroupIndex].resources.length-1].dateadded ){
						clonedPanel.groups[groupIndex].subgroups[subgroupIndex].resources.unshift(newResource);
					}else if(clonedPanel.groups[groupIndex].subgroups[subgroupIndex].reverseOrder){
						clonedPanel.groups[groupIndex].subgroups[subgroupIndex].resources.unshift(newResource);
					}
					else{
						clonedPanel.groups[groupIndex].subgroups[subgroupIndex].resources.push(newResource);
					}

					setCurrentPanel(clonedPanel);

				}
			},
			onError: (err) => {
				alert("Something went wrong, please try again.");
				console.log(err.message);
			},
		
		}
	);

	let [
		updateResourceMutation,
		{ loading: updating, data: updated },
	] = useMutation(UPDATE_RESOURCE, {
		onCompleted: (res) => {
			console.log(res)
			if (res && res.updateResource) {
				const {
					groupIndex,
					subgroupIndex,
					resourceIndex,
					id,
					title,
					link,
					databaseId,
					mediaItemUrl,
					dateupdated,
					dateadded,
				} = res.updateResource;
				const updatedResource = {
					id,
					title,
					link: link ? link : "",
					pdf: databaseId
						? {
								databaseId,
								mediaItemUrl,
						  }
						: 0,
					dateupdated,
					dateadded,
				};

				const clonedPanel = cloneDeep(currentPanel);

				clonedPanel.groups[groupIndex].subgroups[
					subgroupIndex
				].resources.splice(resourceIndex, 1, updatedResource);

				setCurrentPanel(clonedPanel);
			}
		},
		onError: (err) => {
			alert("Something went wrong, please try again.");
			console.log(err.message);
		},
	});

	const [deleteResourceMutation, { loading: deleting }] = useMutation(
		DELETE_RESOURCE,
		{
			onCompleted: (res) => {
				const clonedPanel = cloneDeep(currentPanel);
				const { groupIndex, subgroupIndex, resourceIndex } = res.deleteResource;

				clonedPanel.groups[groupIndex].subgroups[
					subgroupIndex
				].resources.splice(resourceIndex, 1);

				setCurrentPanel(clonedPanel);
			},
			onError: (err) => {
				alert("Something went wrong, please try again.");
				console.log(err.message);
			},
		}
	);

	let [reorderPanelContentsMutation, { loading: savingReorder }] = useMutation(
		REORDER_PANEL_CONTENTS,
		{
			onCompleted: (res) => {
				setCurrentPanel(reorderedPanel);
				alert("Save successful!");
				setIsReordering(false);
			},
			onError: (err) => {
				alert("Something went wrong, please try again.");
				console.log(err.message);
			},
		}
	);

	let [reorderPanelsMutation, { loading: savingPanelsReorder }] = useMutation(
		REORDER_PANELS,
		{
			onCompleted: (res) => {
				setInitialSidebarPanels(reorderedSidebarPanels);
				alert("Save successful!");
				setIsReorderingPanels(false);
			},

			onError: (err) => {
				alert("Something went wrong, please try again.");
				console.log(err.message);
			},
		}
	);

	let [moveGroupToPanelMutation] = useMutation(MOVE_GROUP_TO_PANEL, {
		onCompleted: (res) => {
			// setCurrentPanel(reorderedPanel)
			// alert('Save successful!')
			// setIsReordering(false)
			getSidebarPanels();
			toggleLoader(false);
		},
		onError: (err) => {
			toggleLoader(false);
			alert("Something went wrong, please try again.");
			console.log(err.message);
		},
	});

	// Public create, update, delete functions

	const moveGroupToPanel = (
		newPanelId,
		currentPanelId,
		groupTitle,
		groupContents,
		groupIndex
	) => {
		toggleLoader(true);
		const clonedSubgroups = cloneDeep(groupContents);
		clonedSubgroups.forEach((subgroup) => {
			subgroup.resources.forEach((resource) => {
				if (resource.pdf) {
					resource.pdf = resource.pdf.databaseId;
				}
			});
		});

		moveGroupToPanelMutation({
			variables: {
				clientMutationId: generateUniqueId(),
				newPanelId,
				currentPanelId,
				groupTitle,
				groupContents: clonedSubgroups,
				groupIndex,
			},
		});
	};

	const saveReorderedPanels = (panels) => {
		const panelArray = panels.map((panel, index) => {
			return {
				id: panel.resourcePanelId,
				index: index + 1, //since custom field indexes start at 1
			};
		});

		reorderPanelsMutation({
			variables: {
				clientMutationId: generateUniqueId(),
				panels: panelArray,
			},
		});
	};

	const reorderPanelContents = (panelId, panel) => {
		const clonedPanel = cloneDeep(panel);
		clonedPanel.forEach((group) => {
			group.subgroups.forEach((subgroup) => {
				subgroup.resources.forEach((resource) => {
					if (resource.pdf) {
						resource.pdf = resource.pdf.databaseId;
					}
					if (resource.link === null) {
						resource.link = "";
					}
				});
			});
		});

		reorderPanelContentsMutation({
			variables: {
				clientMutationId: generateUniqueId(),
				panelId,
				panelContents: clonedPanel,
			},
		});
	};

	const addCollection = (item) => {
		const clientMutationId = generateUniqueId();
		const id = generateUniqueId();

		if ("groupIndex" in item) {
			addSubgroupMutation({
				variables: {
					clientMutationId,
					id,
					...item,
				},
			});
		} else if ("panelId" in item) {
			addGroupMutation({
				variables: {
					clientMutationId,
					id,
					...item,
				},
			});
		} else {
			addPanelMutation({
				variables: {
					clientMutationId,
					...item,
				},
			});
		}
	};

	const updateTitle = (item) => {
		const clientMutationId = generateUniqueId();

		if ("subgroupIndex" in item) {
			updateSubgroupTitleMutation({
				variables: {
					clientMutationId,
					...item,
				},
			});
		} else if ("groupIndex" in item) {
			updateGroupTitleMutation({
				variables: {
					clientMutationId,
					...item,
				},
			});
		} else {
			updatePanelTitleMutation({
				variables: {
					clientMutationId,
					...item,
				},
			});
		}
	};

	const addResource = (panelId, groupIndex, subgroupIndex, resource) => {
		let documentResource = null;
		if (resource.pdf) {
			documentResource = {
				id: resource.id,
				title: resource.title,
				pdf: resource.pdf.databaseId,
				mediaItemUrl: resource.pdf.mediaItemUrl,
				dateadded: resource.dateadded,
			};
		}

		addResourceMutation({
			variables: {
				clientMutationId: generateUniqueId(),
				panelId,
				groupIndex,
				subgroupIndex,
				resource: documentResource ? documentResource : resource,
			},
		});
	};

	const updateResource = (
		panelId,
		groupIndex,
		subgroupIndex,
		resourceIndex,
		resource
	) => {
		let documentResource = null;

		if (resource.pdf) {
			documentResource = {
				id: resource.id,
				title: resource.title,
				link: "",
				pdf: resource.pdf.databaseId,
				mediaItemUrl: resource.pdf.mediaItemUrl,
				dateupdated: resource.dateupdated,
				dateadded: resource.dateadded,
			};
		}

		updateResourceMutation({
			variables: {
				clientMutationId: generateUniqueId(),
				panelId,
				groupIndex,
				subgroupIndex,
				resourceIndex,
				resource: documentResource ? documentResource : resource,
			},
		});
	};

	const deleteCollection = (item) => {
		const clientMutationId = generateUniqueId();

		if ("resourceIndex" in item) {
			deleteResourceMutation({
				variables: {
					clientMutationId,
					...item,
				},
			});
		} else if ("subgroupIndex" in item) {
			deleteSubgroupMutation({
				variables: {
					clientMutationId,
					...item,
				},
			});
		} else if ("groupIndex" in item) {
			deleteGroupMutation({
				variables: {
					clientMutationId,
					...item,
				},
			});
		} else {
			deletePanelMutation({
				variables: {
					clientMutationId,
					...item,
				},
			});
		}
	};

	return {
		isEditing,
		setIsEditing,
		isReordering,
		setIsReordering,
		toggleReordering,
		isReorderingPanels,
		toggleReorderingPanels,
		saveReorderedPanels,
		addingPanel,
		panelAdded,
		addingGroup,
		groupAdded,
		addingSubgroup,
		subgroupAdded,
		updateTitle,
		updatedSubgroupTitle,
		updatedGroupTitle,
		updatedPanelTitle,
		addResource,
		adding,
		added,
		updateResource,
		updating,
		updated,
		deleting,
		addCollection,
		deleteCollection,
		reorderPanelContents,
		savingReorder,
		initialSidebarPanels,
		currentPanelId,
		setCurrentPanelId,
		currentPanel,
		currentPanelLoading,
		sidebarPanelsLoading,
		reorderedPanel,
		setReorderedPanel,
		reorderedSidebarPanels,
		setReorderedSidebarPanels,
		savingPanelsReorder,
		moveGroupToPanel,
		showLoader,
		getSidebarPanels,
		brokenResource,
	};
};
