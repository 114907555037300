import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { getTodayFormatted } from '../../../utilities/getToday'
import { useAuth } from '../../../utilities/useAuth'
import { generateUniqueId } from '../../../utilities/generateUniqueId'
import ResourceModal from '../../../styles/ResourceModal'
import { useResources } from '../../../utilities/useResources'
import Button from '../../../global/Button'
import theme from '../../../styles/Theme'

const AddResource = ({ panelId, groupIndex, subgroupIndex, toggleModal }) => {
	const auth = useAuth()
	const resources = useResources()
	const [resourceType, setResourceType] = useState('')
	const [file, setFile] = useState('')
	const [type, setType] = useState('')
	const [filePreview, setFilePreview] = useState('')
	const [title, setTitle] = useState('')
	const [link, setLink] = useState('')
	const [removeDateAdded, setRemoveDateAdded] = useState(false)
	const [uploadingFile, setUploadingFile] = useState(false)
	const fileInput = useRef(null)
	const modal = useRef()
	const resourceOptions = useRef()


	const addFile = e => {
		if (e.target.files) {
			setFile(e.target.files[0])

			if (e.target.files[0].type.includes('image')) {
				const src = window.URL.createObjectURL(e.target.files[0])
				setFilePreview(src)
				setType('')
			} else {
				setType('pdf')
			}
		}
	}

	const removeFile = () => {
		fileInput.current.value = ''
		setFile('')
		setFilePreview('')
	}

	const handleUpload = e => {
		e.preventDefault()

		if (!link && !file) {
			alert('You must add a file or a link')
		} else if (file) {
			setUploadingFile(true)
			const endpoint = `${process.env.REACT_APP_GRAPHQL_URI}/wp-json/wp/v2/media`

			const formData = new FormData()
			formData.append('file', file)
			const params = {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${auth.user.jwtAuthToken}`,
				},
				body: formData,
				method: 'POST',
			}

			fetch(endpoint, params)
				.then(data => data.json())
				.then(res => {
					setUploadingFile(false)
					const resource = {
						id: generateUniqueId(),
						title,
						pdf: {
							mediaItemUrl: res.source_url,
							databaseId: res.id,
							__typename: 'MediaItem',
						},
						dateadded: removeDateAdded ? '19890420' : getTodayFormatted(),
					}
					resources.addResource(panelId, groupIndex, subgroupIndex, resource)
				})
				.catch(error => {
					setUploadingFile(false)
					console.log(error)
				})
		} else {
			const resource = {
				id: generateUniqueId(),
				title,
				link,
				dateadded: removeDateAdded ? '19890420' : getTodayFormatted(),
				__typename: 'Page_Customfields_panels_groups_subgroups_resources',
			}
			resources.addResource(panelId, groupIndex, subgroupIndex, resource)
		}
	}

	return (
		<ResourceModal>
			<div className='form-container' ref={modal}>
				{type === 'pdf' && <FontAwesomeIcon className='type' icon='file-pdf' />}
				{(type === 'ppt' || type === 'pptx') && <FontAwesomeIcon className='type' icon='file-powerpoint' />}
				{type !== 'pdf' && type !== 'ppt' && type !== 'pptx' && filePreview && (
					<img src={filePreview} alt='Upload Preview' />
				)}
				<form onSubmit={handleUpload}>
					<div className='head'>
						<h2>Add Resource</h2>
						<button type='button' onClick={() => toggleModal(false)} title='close'>
							<FontAwesomeIcon icon={['far', 'window-close']} />
						</button>
					</div>
					<label htmlFor='title'>
						Label
						<input type='text' onChange={e => setTitle(e.target.value)} value={title} required />
					</label>

					{resourceType === 'file' && (
						<>
							<label htmlFor='file-upload' className='file-upload-label'>
								File
								<input id='file-upload' type='file' onChange={addFile} ref={fileInput} />
							</label>
						</>
					)}

					{resourceType === 'link' && (
						<label htmlFor='link'>
							<span>
								Link{' '}
								<small>
									<i>(to upload an external link instead of a file)</i>
								</small>
							</span>
							<input type='url' onChange={e => setLink(e.target.value)} value={link} />
						</label>
					)}

					{!resourceType && (
						<label htmlFor='resource-type'>
							Select Type
							<div className='resource-type-options' ref={resourceOptions}>
								<button className='type-select' onClick={() => setResourceType('file')}>
									<FontAwesomeIcon icon='file' />
									<p>File</p>
								</button>
								<button className='type-select' onClick={() => setResourceType('link')}>
									<FontAwesomeIcon icon='link' />
									<p>Link</p>
								</button>
							</div>
						</label>
					)}

					<label htmlFor='remove-date-updated' className='remove-date-updated'>
						<input type='checkbox' onChange={() => setRemoveDateAdded(!removeDateAdded)} value={true} />
						<small>Remove/don't include 'added' asterisk.</small>
					</label>

					<div className='add-remove-btns'>
						{file && (
							<Button color={theme.mediumRed} handleClick={removeFile}>
								Remove File
							</Button>
						)}
						<Button type='submit'>
							{resources.adding || uploadingFile ? <FontAwesomeIcon icon='spinner' size='1x' spin /> : 'Add Resource'}
						</Button>
					</div>
				</form>
			</div>
		</ResourceModal>
	)
}

AddResource.propTypes = {
	panelId: PropTypes.number,
	groupIndex: PropTypes.number,
	subgroupIndex: PropTypes.number,
	toggleModal: PropTypes.func,
}

export default AddResource
