import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AnimateHeight from "react-animate-height";
import { Draggable, Droppable } from "react-beautiful-dnd";

import Resource from "./Resource";
import AddResource from "./AddResource";
import TitleInput from "./TitleInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddGroupBtns from "../../../styles/AddGroupBtns";
import { useResources } from "../../../utilities/useResources";
import EditBtns from "./EditBtns";
import Confirm from "../../../global/Confirm";

const Subgroup = ({ panelId, groupIndex, subgroupIndex, subgroup }) => {
	const [isOpen, toggleOpen] = useState(false);
	const [modalShown, toggleModal] = useState(false);
	const [showConfirm, toggleConfirm] = useState(false);
	const [isUpdatingTitle, toggleUpdatingTitle] = useState(false);
	const [isDeleting, toggleDeleting] = useState(false);
	const [isSorted, toggleSorted] = useState(false);

	const resourceContext = useResources();
	let {
		updatedSubgroupTitle,
		updateTitle,
		deleteCollection,
		isEditing,
		isReordering,
		added,
	} = resourceContext;

	useEffect(() => {
		if (isUpdatingTitle && updatedSubgroupTitle) {
			toggleUpdatingTitle(false);
		}
	}, [updatedSubgroupTitle]);

	const handleUpdate = (title) => {
		updateTitle({
			panelId,
			groupIndex,
			subgroupIndex,
			title,
		});
	};

	const handleDelete = () => {
		if (!isDeleting) {
			toggleDeleting(true);
			deleteCollection({
				panelId,
				groupIndex,
				subgroupIndex,
			});
		}
	};

	useEffect(() => {
		if (added) {
			added = false;
			toggleModal(false);
		}
	}, [added]);

	const resourceOrder = (subgroup) => {
		let resourceArray = [...subgroup.resources]
		return resourceArray;
	};

	useEffect(()=>{
		if (isSorted){
	 		subgroup.resources.sort( (a,b) => b.dateadded - a.dateadded)
	 	}
		toggleSorted(false)
	}, [isSorted])

	return (
		<>
			<Draggable draggableId={subgroup.id} index={subgroupIndex}>
				{(provided) => (
					<StyledSubgroup
						open={isOpen}
						isReordering={isReordering}
						ref={provided.innerRef}
						{...provided.draggableProps}
					>
						<div
							className='subgroup-top'
							onClick={() => toggleOpen(!isOpen)}
							role='button'
						>
							<div className='left'>
								<div className='drag-handle' {...provided.dragHandleProps}>
									<FontAwesomeIcon icon='grip-vertical' />
								</div>
								{isEditing && isUpdatingTitle ? (
									<TitleInput
										currentTitle={subgroup.title}
										submit={handleUpdate}
										cancel={toggleUpdatingTitle}
									/>
								) : (
									<>
										<h5>{subgroup.title}</h5>
										<EditBtns
											show={isEditing}
											toggleEdit={toggleUpdatingTitle}
											toggleConfirm={toggleConfirm}
											isDeleting={isDeleting}
											grey
										/>
										{isReordering && <button className="sort-button" onClick={(e) => { e.stopPropagation(); toggleSorted(!isSorted)}}>Sort By Newest </button>}
									</>
								)}
							</div>

							<div className='plus-vertical'></div>
							<div className='plus-horizontal'></div>
						</div>
						<AnimateHeight duration={500} height={isOpen ? "auto" : 0}>
							<div className='resource-dropdown'>
								<Droppable droppableId={subgroup.id} type='resources'>
									{(provided) => (
										<ul
											className='resource-list'
											ref={provided.innerRef}
											{...provided.droppableProps}
										>
											{subgroup.resources &&
												subgroup.resources.map((resource, index) => {
													let type = "empty";

													if (resource.link) {
														type = "link";
													} else if (
														resource.pdf &&
														resource.pdf.mediaItemUrl
													) {
														type = resource.pdf.mediaItemUrl.split(".").pop();
													}

													return (
														<Resource
															panelId={panelId}
															groupIndex={groupIndex}
															subgroupIndex={subgroupIndex}
															resourceIndex={index}
															resource={resource}
															type={type}
															key={resource.id}
														/>
													);
												})}
											{provided.placeholder}
										</ul>
									)}
								</Droppable>
								{isEditing && (
									<AddGroupBtns>
										<button
											className='add-resource-btn'
											onClick={() => toggleModal(!modalShown)}
										>
											Add Resource
											<FontAwesomeIcon icon='file-upload' />
										</button>
									</AddGroupBtns>
								)}
							</div>
						</AnimateHeight>
					</StyledSubgroup>
				)}
			</Draggable>

			{modalShown && (
				<AddResource
					panelId={panelId}
					groupIndex={groupIndex}
					subgroupIndex={subgroupIndex}
					toggleModal={toggleModal}
				/>
			)}

			{showConfirm && (
				<Confirm
					password={subgroup.title}
					close={() => toggleConfirm(false)}
					callback={handleDelete}
				>
					<p>
						Are you sure you want to delete this subgroup and everything within
						it?
					</p>
				</Confirm>
			)}
		</>
	);
};

const StyledSubgroup = styled.div`
	background: rgba(0, 0, 0, 0.1);
	margin: 15px;
	padding: 13px;

	.subgroup-top {
		color: ${(props) => props.theme.lightText};
		position: relative;
		text-align: left;
		width: 100%;
		background: none;
		border: none;
		cursor: pointer;

		.left {
			display: flex;
			align-items: center;
			flex: 1;

			h5 {
				margin: 0;
				margin-right: 15px;
				font-size: 14px;
				padding: 5px 0;
			}

			.drag-handle {
				padding: ${(props) => (props.isReordering ? "0 10px" : "0")};
				width: ${(props) => (props.isReordering ? "35px" : "0")};
				overflow: hidden;
			}
		}

		.plus-vertical,
		.plus-horizontal {
			height: 12px;
			width: 2px;
			background-color: ${(props) => props.theme.lightText};
			border-radius: 2px;
			position: absolute;
			right: 10px;
			top: 8px;
			transition: transform 0.2s ${(props) => props.theme.animation};
		}

		.plus-horizontal {
			transform: rotate(90deg);
		}

		.plus-vertical {
			transform: ${(props) => (props.open ? "rotate(90deg)" : "rotate(0deg)")};
		}
	}

	.resource-dropdown {
		.resource-list {
			list-style: none;
			padding: 0;
		}
	}
	.sort-button{
		background-color:#06549b;
		color:white;
		border:none;
		padding:10px;
	}
`;

export default Subgroup;
