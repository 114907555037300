import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Modal = ({ children, toggleModal }) => {
	return (
		<StyledModal>
			<div className='content'>
				<FontAwesomeIcon
					className='close-modal-btn'
					icon={['far', 'window-close']}
					onClick={() => toggleModal(false)}
				/>
				{children}
			</div>
		</StyledModal>
	)
}

const StyledModal = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(60, 60, 60, 0.25);
	z-index: 200;

	.content {
		width: 60%;
		min-width: 500px;
		max-width: 1000px;
		background: #fff;
		position: relative;
		padding: 50px;

		@media (max-width: 500px) {
			width: 100%;
			min-width: 100%;
		}
	}

	.close-modal-btn {
		color: ${props => props.theme.darkText};
		font-size: 24px;
		position: absolute;
		top: 25px;
		right: 25px;
		cursor: pointer;
		transition: opacity 0.2s ${props => props.theme.animation};

		&:hover {
			opacity: 0.7;
		}
	}
`

export default Modal
