/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DragDropContext } from 'react-beautiful-dnd'
import { useCookies } from 'react-cookie'

import { reorderGroups, reorderSubgroups, reorderResources } from '../../utilities/newReorder'
import Sidebar from './components/Sidebar'
import Panel from './components/Panel'
import { useResources } from '../../utilities/useResources'
import Loader from '../../global/Loader'
import Modal from '../../global/Modal'
import SquaredButton from '../../styles/SquaredButton'

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

const ResourcesPage = () => {
	const [cookies, setCookie] = useCookies()
	const [showMessagePopup, toggleMessagePopup] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const panelRef = useRef(null)
	const resources = useResources()

	const {
		showLoader,
		currentPanel,
		currentPanelLoading,
		currentPanelId,
		reorderedPanel,
		setReorderedPanel,
		sidebarPanelsLoading,
		isReordering,
	} = resources

	useEffect(() => {
		if (!cookies.resourceRedesign) {
			toggleMessagePopup(true)
		}
	}, [])

	// if reordering, throw initial panel into state for editing.
	useEffect(() => {
		if (isReordering) {
			setReorderedPanel(currentPanel)
		} else {
			setReorderedPanel({})
		}
	}, [isReordering])

	// scroll to the top of the panel whenever a new panel is selected
	useEffect(() => {
		if (panelRef.current) {
			scrollToRef(panelRef)
		}
	}, [currentPanelId])

	if (!currentPanel.resourcePanelId) return <Loader />

	const onDragEnd = result => {
		let newPanel

		if (
			result.destination.droppableId === result.source.droppableId &&
			result.destination.index === result.source.index
		) {
			return
		}

		if (result.destination) {
			switch (result.type) {
				case 'groups':
					newPanel = reorderGroups(result, reorderedPanel)
					break
				case 'subgroups':
					newPanel = reorderSubgroups(result, reorderedPanel)
					break
				case 'resources':
					newPanel = reorderResources(result, reorderedPanel)
					break
				default:
			}

			setReorderedPanel(newPanel)
		}
	}

	const handleToggleMessagePopup = () => {
		setCookie('resourceRedesign', true, { path: '/', maxAge: 31536000 });
		toggleMessagePopup(false)
	}



	return (
		<>
			<Page ref={panelRef}>
				{(currentPanelLoading || sidebarPanelsLoading || showLoader) && (
					<div className='cover'>
						<h3>
							<FontAwesomeIcon icon='spinner' size='1x' spin />
						</h3>
					</div>
				)}
				{currentPanel && currentPanel.resourcePanelId && <Sidebar searchTerm ={searchTerm} setSearchTerm={setSearchTerm}  />}
				<DragDropContext onDragEnd={onDragEnd}>
					<div className='resources-section'>
						{currentPanel && currentPanel.resourcePanelId && (
							<Panel currentPanel={currentPanel} reorderedPanel={reorderedPanel}  searchTerm={searchTerm} setSearchTerm={setSearchTerm}  />
						)}
					</div>
				</DragDropContext>
			</Page>
			{showMessagePopup && (
				<Modal toggleModal={handleToggleMessagePopup}>
					<h2>Welcome Back to the AMAC Action Website!</h2>
					<p style={{ borderBottom: '1px solid rgba(50, 50, 50, 0.1)', paddingBottom: '1em' }}>
						We have redesigned our website to accommodate the growth of AMAC Action and our Advocate participation.
					</p>
					<SquaredButton onClick={handleToggleMessagePopup} style={{ marginLeft: 0 }} save>
						Got it!
					</SquaredButton>
				</Modal>
			)}
		</>
	)
}

const Page = styled.div`
	position: relative;
	display: flex;
	background: #f5f8fd;

	.cover {
		position: fixed;
		z-index: 101;
		top: 97px;
		left: 0;
		width: 100%;
		height: calc(100% - 97px);
		background: ${props => props.theme.modalOverlay};
		display: flex;
		justify-content: center;
		align-items: center;

		h3 {
			padding: 25px;
			color: #f5f8fd;
			background: rgba(50, 50, 50, 0.7);
		}
	}

	.resources-section {
		min-height: 100vh;
		flex: 1;
		padding: 25px;
	}
`

export default ResourcesPage
