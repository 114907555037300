import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { GET_USERS } from '../../graphql/queries';
import Sidebar from './Sidebar';
import UserForm from './UserForm';

const Admin = () => {
  const [formFunction, setFormFunction] = useState('create');
  const { id } = useParams();

  const { loading, data } = useQuery(GET_USERS);

  useEffect(() => {
    if (id) {
      setFormFunction('edit');
    }
  }, [id]);

  return (
    <AdminPage>
      <Sidebar active={formFunction} setActive={setFormFunction} />
      <div className="content">
        <UserForm
          formFunction={formFunction}
          loading={loading}
          users={data ? data.users.edges : null}
        />
      </div>
    </AdminPage>
  );
};

const AdminPage = styled.div`
  display: flex;
`;

export default Admin;
