import styled from 'styled-components'

const Fab = styled.div`
	margin: 0 20px;
	display: flex;
	justify-content: flex-end;

	.add-collection-btn {
		background: ${props => props.theme.mediumOrange};
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		border: none;
		width: 40px;
		height: 40px;
		cursor: pointer;
		position: relative;
		box-shadow: 2px 5px 4px ${props => props.theme.mediumOrange}66;

		p {
			opacity: 0;
			position: absolute;
			right: 65%;
			background: ${props => props.theme.mediumOrange}a8;
			font-weight: bold;
			font-size: 12px;
			margin: 0;
			padding: 5px 10px;
			width: 115px;
			border-top-left-radius: 15px;
			border-bottom-left-radius: 15px;
			transition: all 0.25s ${props => props.theme.animation};
		}

		&:hover p {
			opacity: 1;
			right: 85%;
		}
	}
`

export default Fab
