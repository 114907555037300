import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Success = ({ message }) => <SuccessMessage>{message}</SuccessMessage>;

const SuccessMessage = styled.div`
  width: 100%;
  background-color: #a9f9cb;
  border-left: 4px solid #2bc46b;
  padding: 15px;
  color: #2bc46b;
  margin: 10px auto;
`;

Success.propTypes = {
  message: PropTypes.string,
};

export default Success;
