import gql from 'graphql-tag';

export const ADD_SUBGROUP = gql `
  mutation addSubgroup(
    $clientMutationId: String!
    $panelId: Int!
    $groupIndex: Int!
    $id: String!
    $title: String!
  ) {
    addSubgroup(
      input: {
        clientMutationId: $clientMutationId
        panelId: $panelId
        groupIndex: $groupIndex
        id: $id
        title: $title
      }
    ) {
      panelId
      groupIndex
      id
      title
    }
  }
`;

export const DELETE_SUBGROUP = gql `
  mutation deleteSubgroup(
    $clientMutationId: String!
    $panelId: Int!
    $groupIndex: Int!
    $subgroupIndex: Int!
  ) {
    deleteSubgroup(
      input: {
        clientMutationId: $clientMutationId
        panelId: $panelId
        groupIndex: $groupIndex
        subgroupIndex: $subgroupIndex
      }
    ) {
      panelId
      groupIndex
      subgroupIndex
    }
  }
`;

export const UPDATE_SUBGROUP_TITLE = gql `
  mutation updateSubgroupTitle(
    $clientMutationId: String!
    $panelId: Int!
    $groupIndex: Int!
    $subgroupIndex: Int!
    $title: String!
  ) {
    updateSubgroupTitle(
      input: {
        clientMutationId: $clientMutationId
        panelId: $panelId
        groupIndex: $groupIndex
        subgroupIndex: $subgroupIndex
        title: $title
      }
    ) {
      panelId
      groupIndex
      subgroupIndex
      title
    }
  }
`;