import styled from 'styled-components'

const ResourceModal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 200;
	width: 100vw;
	height: 100vh;
	background-color: ${props => props.theme.modalOverlay};
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: ${props => props.theme.defaultShadow};

	.form-container {
		background-color: #fff;
		border-radius: 11px;
		min-height: 250px;
		min-width: 400px;
		max-width: 90%;
		max-height: 90%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		padding: 15px;

		form {
			min-width: 225px;
		}

		.head {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			button {
				background: none;
				border: none;
				font-weight: bold;
				color: ${props => props.theme.lightText};
				padding: 0;
				cursor: pointer;
				font-size: 24px;

				&:hover {
					color: ${props => props.theme.darkText};
				}
			}
		}

		h2 {
			margin: 0 0 20px;
		}

		img {
			max-width: 700px;
			max-height: 450px;
			margin: 15px;
		}

		svg.type {
			font-size: 50px;
			color: ${props => props.theme.mediumRed};
			margin-right: 25px;
		}

		.add-remove-btns {
			button:first-child {
				margin-right: 10px;
			}
		}

		.resource-type-options {
			display: flex;
			justify-content: space-around;
			margin-top: 15px;

			button {
				background: none;
				border: none;
				cursor: pointer;

				svg {
					font-size: 63px;
					transition: all 0.2s ${props => props.theme.animation};
				}

				&:hover svg {
					color: black;
				}
			}
		}
	}

	.file-upload-label {
		max-width: 250px;
	}

	label {
		display: flex;
		flex-direction: column;
		color: ${props => props.theme.lightText};
		font-weight: bold;
		font-size: 18px;
		margin: 10px auto;

		input {
			margin: 10px 0;
		}

		small {
			font-weight: 400;
			font-size: 11px;
		}

		&.remove-date-updated {
			flex-direction: row;
			align-items: center;

			input {
				margin-right: 15px;
			}
		}
	}

	.button-row {
		display: flex;
		justify-content: space-around;
	}
`

export default ResourceModal
