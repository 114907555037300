import gql from 'graphql-tag'

export const ADD_PANEL = gql`
	mutation addPanel($clientMutationId: String!, $title: String!, $index: Int!) {
		addPanel(input: { clientMutationId: $clientMutationId, title: $title, index: $index }) {
			post_id
			title
		}
	}
`

export const DELETE_PANEL = gql`
	mutation deletePanel($clientMutationId: String!, $panelId: Int!) {
		deletePanel(input: { clientMutationId: $clientMutationId, panelId: $panelId }) {
			panelId
		}
	}
`

export const UPDATE_PANEL_TITLE = gql`
	mutation updatePanelTitle($clientMutationId: String!, $panelId: Int!, $title: String!) {
		updatePanelTitle(input: { clientMutationId: $clientMutationId, panelId: $panelId, title: $title }) {
			panelId
			title
		}
	}
`

export const REORDER_PANEL_CONTENTS = gql`
	mutation reorderPanelContents($clientMutationId: String!, $panelId: Int!, $panelContents: [Group]) {
		reorderPanelContents(
			input: { clientMutationId: $clientMutationId, panelId: $panelId, panelContents: $panelContents }
		) {
			clientMutationId
			panelId
		}
	}
`

export const REORDER_PANELS = gql`
	mutation reorderPanels($clientMutationId: String!, $panels: [Panel]) {
		reorderPanels(input: { clientMutationId: $clientMutationId, panels: $panels }) {
			clientMutationId
		}
	}
`

export const MOVE_GROUP_TO_PANEL = gql`
	mutation moveGroupToPanel(
		$clientMutationId: String!
		$newPanelId: Int!
		$currentPanelId: Int!
		$groupTitle: String!
		$groupContents: [SubGroup]
		$groupIndex: Int!
	) {
		moveGroupToPanel(
			input: {
				clientMutationId: $clientMutationId
				newPanelId: $newPanelId
				currentPanelId: $currentPanelId
				groupTitle: $groupTitle
				groupContents: $groupContents
				groupIndex: $groupIndex
			}
		) {
			clientMutationId
		}
	}
`
