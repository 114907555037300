import React, { useState } from 'react'
import styled from 'styled-components'

import Button from './Button'

const Confirm = ({ close, password, callback, children }) => {
	const [input, setInput] = useState('')

	const handleConfirm = () => {
		if (!password || password === input) {
			callback()
			close()
		}
	}

	return (
		<StyledConfirm>
			<div className='modal-card'>
				<div className='content'>
					{password ? (
						<>
							{children}
							<p>
								Please type <b>{password}</b> below in order to confirm.
							</p>
							<input type='text' value={input} onChange={e => setInput(e.target.value)} />
						</>
					) : (
						children
					)}
				</div>
				<div className='footer'>
					<Button color='#e0e1e2' handleClick={close} darkText>
						Cancel
					</Button>
					<Button handleClick={handleConfirm} disabled={password && input !== password}>
						Confirm
					</Button>
				</div>
			</div>
		</StyledConfirm>
	)
}

export default Confirm

const StyledConfirm = styled.div`
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background: ${props => props.theme.modalOverlay};
	display: flex;
	align-items: center;
	justify-content: center;

	.modal-card {
		width: 50%;
		min-width: 300px;
		background: #fff;
		border-radius: 10px;
		color: ${props => props.theme.darkText};
		z-index: 110;

		.content {
			padding: 25px;

			input {
				padding: 5px;
				width: 65%;
				min-width: 250px;
			}
		}

		.footer {
			background: #f5f8fd;
			border-top: 1px solid #ccc;
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 15px 25px;

			button:first-child {
				margin-right: 10px;
			}
		}
	}
`
